import {DateTimePicker} from "@Redak/Common/DateTime";
import React, {ReactElement} from "react";

export function HeaderPlanningCalendar(
    props: {
        onChangeHeightCalendar: Function,
        onChangeMonthPrev: Function,
        onChangeMonthNext: Function,
        handleChangeDate: Function,
        onHandleReset: Function,
        heightCalendar: number,
        month: string,
        start: any,
        end: any,
    }
): ReactElement {
    const {
        onChangeHeightCalendar, onChangeMonthPrev, onChangeMonthNext,
        onHandleReset, handleChangeDate, heightCalendar, month,
        start, end
    } = props;

    return (
        <div className="container-calendar-navigation">
            <div className="container-calendar-navigation-left">
                <div className="mr-3">
                    <DateTimePicker
                        onChangeHandler={(e: string) => handleChangeDate(e, 'startRedak', 'AFTER')}
                        placeholder={'Date de début'}
                        value={start}
                    />
                </div>
                <div className="mr-3">
                    <DateTimePicker
                        onChangeHandler={(e: string) => handleChangeDate(e, 'endRedak', 'BEFORE')}
                        placeholder={'Date de fin'}
                        value={end}
                    />
                </div>
                <button
                    onClick={() => onHandleReset()}
                    className="btn btn-primary"
                >
                    Supprimer les filtres
                </button>
            </div>
            <div className="container-calendar-navigation-right">
                <div className="btn btn-circle btn-primary mr-1"
                     onClick={() => onChangeMonthPrev()}
                >
                    <i className="fas fa-angle-left fa-1x"></i>
                </div>
                <div className="btn btn-circle btn-primary ml-1"
                     onClick={() => onChangeMonthNext()}
                >
                    <i className="fas fa-angle-right fa-1x"></i>
                </div>
                <span className="current-month">
                    {month}
                </span>
                <div className="btn btn-circle btn-primary mr-1"
                     onClick={() => onChangeHeightCalendar(heightCalendar / 1.5)}
                >
                    <i className="fas fa-minus fa-1x"></i>
                </div>
                <div className="btn btn-circle btn-primary ml-1"
                     onClick={() => onChangeHeightCalendar(heightCalendar * 1.5)}
                >
                    <i className="fas fa-plus fa-1x"></i>
                </div>
            </div>
        </div>
    );
}