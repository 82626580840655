export const FormError = (props: {error: any}) => {
    const {error} = props;

    return (
        <div className="text-danger">
            {error}
        </div>
    )
}

export default FormError;