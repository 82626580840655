import {ISchedule} from "tui-calendar";
import moment from "moment";
import {prepareCalendar} from "@Redak/utils/Calendar/prepare.calendar";
import {theme} from "@Redak/utils/Calendar/theme";
import Calendar from "@toast-ui/react-calendar";
import React, {ReactElement} from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";

export function PlanningCalendar(props: {
    height: number,
    calendarRef: any,
    handleShow: Function,
    mettings: Array<MettingType>
}): ReactElement {
    const {height, calendarRef, handleShow, mettings} = props;
    return(
        <Calendar
            onClickSchedule={(eventObj) => handleShow(eventObj.schedule)}
            week={{
                daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                showTimezoneCollapseButton: false,
                timezonesCollapsed: false,
                hourStart: 8,
                hourEnd: 20
            }}
            month={{
                daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                visibleScheduleCount: 50, // * Nombre de reunions visibles
                startDayOfWeek: 1, // * Début de semaine "lundi"
                isAlways6Week: false
            }}
            template={{
                monthGridHeaderExceed: (hiddenSchedules: number) => {
                    return (
                        `
                            <span class="tui-full-calendar-weekday-grid-more-schedules">
                               ${hiddenSchedules} reunion${hiddenSchedules > 1 ? 's' : ''}
                            </span>
                        `
                    );
                },
            }}
            schedules={prepareCalendar(mettings)}
            height={`${String(height)}px`}
            useCreationPopup={false}
            useDetailPopup={false}
            disableDblClick={true}
            disableClick={true}
            scheduleView={true}
            isReadOnly={true}
            draggable={false}
            ref={calendarRef}
            taskView={false}
            view={'month'}
            theme={theme}
        />
    );
}