import {MettingType} from "@Redak/types/Metting/MettingType";
import {IProofreaderBaseActions, ProofreaderActionType} from "@Redak/redux/reducers/Proofreader/actions";
import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";

export interface ProofreaderStateType {
    proofreaders: Array<MettingType> | []
    proofreadersCopy: Array<MettingType> | []
}

export const initialProofreaderState: ProofreaderStateType = {
    proofreaders: [],
    proofreadersCopy: []
}

export const proofreaderReducer = (
    state: any = initialProofreaderState, action: IProofreaderBaseActions
) => {
    switch (action.type) {
        case ProofreaderActionType.SET_PROOFREADERS: {
            return {
                ...state,
                proofreaders: action.payload
            }
        }
        case ProofreaderActionType.PUSH_PROOFREADER: {
            let current = state.proofreaders.findIndex(
                (elt : ProofreaderType) => elt.id === action.payload.id)

            if(current === -1) {
                return {
                    ...state,
                    ...state.proofreaders.push(action.payload),
                }
            }

            return state;
        }
        case ProofreaderActionType.SET_PROOFREADERS_COPY: {
            return {
                ...state,
                proofreadersCopy: action.payload
            }
        }
        case ProofreaderActionType.RESET_PROOFREADERS_COPY: {
            return {
                ...state,
                proofreadersCopy: state.proofreaders
            }
        }
        default: {
            return state;
        }
    }
}