import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {useAppDispatch} from "@Redak/redux/hooks";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {CardMetting} from "@Redak/Common/Metting/Card/CardMetting";
import MettingEntryClosing from "@Redak/Common/Metting/View/Proofreader/MettingEntryClosing";
import MettingEntryHoursEdit from "@Redak/Common/Metting/View/Proofreader/MettingEntryHoursEdit";

export function Planning() {
    const {mettings} = useSelector<RootState, {
        mettings: MettingType[]
    }>((state) => state.mettings);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const handleEditEntryHours = (metting: MettingType) => {
        dispatch(
            push(
                <MettingEntryHoursEdit metting={metting} isManagement={false} />, {
                    title: metting.buisness.toUpperCase(),
                    className: 'modal-child-proofreader',
                    type: 'update'
                }
            )
        );
    }

    const handleClosingEntryHours = (metting: MettingType) => {
        dispatch(
            push(
                <MettingEntryClosing initialMetting={metting} />, {
                    title: metting.buisness.toUpperCase(),
                    className: 'modal-child-proofreader',
                    type: 'update'
                }
            )
        );
    }

    return (
        <div className="d-flex flex-column align-items-center" style={{backgroundColor: '#edf1f5'}}>
            <div className="col-12 mt-3">
                {!mettings.length && (
                    <div className="card">
                        <div className="card-body">
                            <p className="card-text">
                                Aucune réunion disponible
                            </p>
                        </div>
                    </div>
                )}

                {mettings.map((metting: MettingType, index: Number) => (
                    <CardMetting
                        handleClosingEntryHours={handleClosingEntryHours}
                        handleEditEntryHours={handleEditEntryHours}
                        key={Number(index)}
                        className={'mx-2'}
                        metting={metting}
                        isAction={true}
                    />
                ))}
            </div>
        </div>
    )
}

export default Planning;