import {MettingType} from "@Redak/types/Metting/MettingType";
import {IMettingBaseActions, MettingActionType} from "@Redak/redux/reducers/Metting/actions";

export interface MettingStateType {
    mettings: Array<MettingType> | [],
    mettingsCopy: Array<MettingType> | [],
}

export const initialMettingState: MettingStateType = {
    mettings: [],
    mettingsCopy: [],
}

export const mettingReducer = (
    state: any = initialMettingState, action: IMettingBaseActions
) => {
    switch (action.type) {
        case MettingActionType.SET_METTINGS: {
            return {
                ...state,
                mettings: action.payload
            }
        }
        case MettingActionType.SET_METTINGS_COPY: {
            return {
                ...state,
                mettingsCopy: action.payload
            }
        }
        case MettingActionType.PUSH_MAILER_IN_METTING: {
            const mettingIndex = state.mettings.findIndex(
                (elt: MettingType) =>
                    elt.id === action.metting?.id
            );

            return{
                ...state,
                ...state.mettings[mettingIndex] = {
                    ...state.mettings[mettingIndex],
                    mailers: action.payload
                },
            }
        }
        case MettingActionType.UPDATE_METTING_WORK_TIME: {
            const mettingIndex = state.mettings.findIndex(
                (elt: MettingType) =>
                    elt.id === action.metting?.id
            );

            return{
                ...state,
                ...state.mettings[mettingIndex] = {
                    ...state.mettings[mettingIndex],
                    timeProofreader: action.payload
                },
            }
        }
        case MettingActionType.UPDATE_METTING: {
            const mettingIndex = state.mettings.findIndex(
                (elt: MettingType) =>
                    elt.id === action.payload.id
            );

            return{
                ...state,
                ...state.mettings[mettingIndex] = {
                    ...action.payload
                },
                ...state.mettingsCopy[mettingIndex] = {
                    ...action.payload
                }
            }
        }
        default: {
            return state;
        }
    }
}