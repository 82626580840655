import {MettingType} from "@Redak/types/Metting/MettingType";
import {WorkTimeType} from "@Redak/types/WorkTime/WorkTimeType";
import {MailerType} from "@Redak/types/Mailer/MailerType";

export enum MettingActionType {
    SET_METTINGS = 'SET_METTINGS',
    SET_METTINGS_COPY = 'SET_METTINGS_COPY',
    UPDATE_METTING = 'UPDATE_METTING',
    PUSH_MAILER_IN_METTING = 'PUSH_MAILER_IN_METTING',
    UPDATE_METTING_WORK_TIME = 'UPDATE_METTING_WORK_TIME'
}

export interface IMettingBaseActions {
    type: MettingActionType,
    metting?: MettingType,
    payload?: any,
}

const setMettings = (mettings: Array<MettingType>) => (
    { type: MettingActionType.SET_METTINGS, payload: mettings }
)

const setMettingsCopy = (mettings: Array<MettingType>) => (
    { type: MettingActionType.SET_METTINGS_COPY, payload: mettings }
)

const updateMetting = (metting: MettingType) => (
    { type: MettingActionType.UPDATE_METTING, payload: metting }
)

const updateMettingWorkTime = (metting: MettingType, workTime: WorkTimeType) => (
    { type: MettingActionType.UPDATE_METTING_WORK_TIME, metting: metting, payload: workTime }
)

const pushMailerInMetting = (metting: MettingType, mailers: MailerType[]) => (
    { type: MettingActionType.PUSH_MAILER_IN_METTING, metting: metting, payload: mailers }
)

export default {
    setMettings,
    updateMetting,
    setMettingsCopy,
    pushMailerInMetting,
    updateMettingWorkTime,
}