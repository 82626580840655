import {MettingType} from "@Redak/types/Metting/MettingType";
import {store} from "@Redak/redux/store";
import moment from "moment";

export function FilterDateMetting(mettings: Array<MettingType>, property: string, date: string, type: string): Array<MettingType> {
    const results: Array<MettingType> = [];
    store.getState().mettings.mettings.map((metting: MettingType) => {
        if(type === 'AFTER') {
            // @ts-ignore
            if(moment(metting[property]).isAfter(moment(date))) {
                // @ts-ignore
                results.push(metting);
            }
        }else if(type === 'BEFORE'){
            // @ts-ignore
            if(moment(metting[property]).isBefore(moment(date))) {
                results.push(metting);
            }
        }
    });
    return results;
}