import React from "react";
import {Route, Routes} from "react-router-dom";
import {Container} from "@Redak/Common/Container";
import {Breadcrumb} from "@Redak/Common/Breadcrumb";
import {Dashboard} from "@Redak/Screen/Management/Dashboard";
import ManagementNavigation from "@Redak/Screen/Management/ManagementNavigation";
import Table from "@Redak/Screen/Management/Table";

export function Management() {
    return (
        <>
           <aside className="left-sidebar">
                <ManagementNavigation />
            </aside>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <Breadcrumb />
                    <div id="container-management">
                        <Container>
                            <Routes>
                                <Route path={'/table'} element={<Table />} />
                                <Route path={'/calendar'} element={<Dashboard />} />
                                <Route path={'/calendar/:id'} element={<Dashboard />} />
                            </Routes>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Management;