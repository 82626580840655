import FormError from "@Redak/Common/Forms/FormError";

export const FormField = (
    props: {
        className?: string,
        label?: string,
        error?: any,
        children: any,
        name?: string
    },
) => {
    const {
        className, label, error, children, name
    } =  props;

    return(
        <div className={`form-field ${className}`}>
            {
                label && (
                    <label htmlFor={name}>
                        {label}
                    </label>
                )
            }
            <div className="form-group">
                {children}
                {error && <FormError error={error} />}
            </div>
        </div>
    )
}


export default FormField;