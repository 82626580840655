import React from "react";
import {AlertType} from "@Redak/Common/Alert/type";

export const Alert = (props: {alert: AlertType}) => {
    const {alert} = props;
    return (
        <div className={`alert ${alert.type}`}>
            <p className="m-0 p-0">
                {alert.message}
            </p>
        </div>
    )
}