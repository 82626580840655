import {useSelector} from "react-redux";
import {RootState} from "@Redak/redux/store";
import {ToastType} from "@Redak/redux/reducers/Toaster";
import {ToastCard} from "@Redak/Common/Toaster/View/Toast";

export function Toaster() {
    const toasts = useSelector<RootState, ToastType[]>(
        (state) => state.toaster.toasts
    );

    if (toasts.length === 0) return <></>;

    return (
        <div className="app-toaster">
            {toasts.map((toast: any) => (
                <ToastCard key={JSON.stringify(toast)} toast={toast} />
            ))}
        </div>
    )
}

export default Toaster;