import React from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface DateTimePickerType {
    placeholder: string,
    onChangeHandler: Function,
    value: string | undefined
}

export function DateTimePicker(props: DateTimePickerType) {
    const {placeholder, onChangeHandler, value} = props;
    return (
        <DatePicker
            value={value ? moment(value).format('DD-MM-YYYY') : ''}
            onChange={(e) => onChangeHandler(e)}
            placeholderText={`${placeholder}`}
            className="form-control"
            locale="fr"
        />
    )
}