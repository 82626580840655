import React from 'react';
import {Navbar, NavItem, NavItemListProofreader} from "@Redak/Common/Navbar";
import {NavLink} from "react-router-dom";
import ROUTE from "@Redak/Services/navigation.all";
import {useSelector} from "react-redux";
import {RootState} from "@Redak/redux/store";
import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";

export function ManagementNavigation() {
    const {proofreaders} = useSelector<RootState, {
        proofreaders: ProofreaderType[]
    }>((state) => state.proofreaders);

    return (
        <Navbar>
            <NavItem>
                <NavLink
                    className={(navdata) => (
                        navdata.isActive ?
                            'has-arrow waves-effect active'
                            : 'has-arrow waves-effect'
                    )}
                    to={ROUTE.MANAGEMENT.TABLE}
                >
                    <i className="fas fa-table"></i>
                    <span className="hide-menu">Tableau</span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={(navdata: {isActive: boolean}) => (
                        navdata.isActive ?
                            'has-arrow waves-effect waves-dark active'
                            : 'has-arrow waves-effect waves-dark'
                    )}
                    to={ROUTE.MANAGEMENT.CALENDAR}
                >
                    <i className="fas fa-users"></i>
                    <span className="hide-menu">Rédacteurs</span>
                </NavLink>
            </NavItem>
            <NavItemListProofreader proofreaders={proofreaders} />
        </Navbar>
    );
}

export default ManagementNavigation;