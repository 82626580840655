import {CustomerType} from "@Redak/types/Customer/CustomerType";
import {CustomerActionType, ICustomerBaseActions} from "@Redak/redux/reducers/Customer/actions";

export interface CustomerStateType {
    customers: Array<CustomerType> | []
}

export const initialCustomerState: CustomerStateType = {
    customers: []
}

export const customerReducer = (
    state: any = initialCustomerState, action: ICustomerBaseActions
) => {
    switch (action.type) {
        case CustomerActionType.SET_CUSTOMERS: {
            return {
                ...state,
                customers: action.payload
            }
        }
        default: {
            return state;
        }
    }
}