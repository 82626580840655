import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import TokenStorage from "@Redak/Services/TokenStorage";
import axios, {
    AxiosError, AxiosRequestConfig,
} from 'axios';

const axiosBaseQuery = (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<
    {
        url: string
        method: AxiosRequestConfig['method']
        params?: AxiosRequestConfig['params']
        data?: AxiosRequestConfig['data']
        withoutBaseUrl?: boolean,
    },
    unknown,
    unknown
    > => async ({
                    url, method, params, data, withoutBaseUrl = false,
                }) => {
    const axiosConfig = {
        headers: TokenStorage.getAuthentification().headers,
    };
    const calledUrl = withoutBaseUrl ? url : baseUrl + url;
    try {
        const result = await axios({
            url: calledUrl, method, params, data, ...axiosConfig,
        });
        return { data: result.data };
    } catch (axiosError) {
        const err = axiosError as AxiosError;
        return {
            error: { status: err.response?.status, data: err.response?.data },
        };
    }
};

export default axiosBaseQuery;
