import React from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {TimeEntry} from "@Redak/types/WorkTime/WorkTimeType";
import moment from "moment";
import {calculatePourcentage} from "@Redak/utils/Time/CalculateTime";

export const TimeWorkTableDetails = (props: {metting: MettingType}): React.ReactElement => {
    const {metting} = props;

    return (
        <>
            <div className="container-table-multiple-view">
                <div className="table-head-multiple table-success">
                    Tous les index
                </div>
                <div className="table-fields-name-multiple">
                    <div className="table-field-name-multiple-recap">Date</div>
                    <div className="table-field-name-multiple-recap">Time code</div>
                   {/* <div className="table-field-name-multiple-recap">Début et fin</div>
                    <div className="table-field-name-multiple-recap">Durée</div>*/}
                    <div className="table-field-name-multiple-recap">%</div>
                </div>
                <div>
                    {metting.timeProofreader?.times.map((time: TimeEntry, index: number) => (
                        <div className="container-table-fields" key={String(index)}>
                            <div className="table-field-recap">
                                <div className="field-title"><span>Début</span></div>
                                <div className="field">
                                    {moment(time.date).format('ll')}
                                </div>
                            </div>
                            <div className="table-field-recap">
                                <div className="field-title"><span>Time code</span></div>
                                <div className="field">
                                    {time.timeCode === null ? (
                                       '--:--:--'
                                    ): time.timeCode}
                                </div>
                            </div>
                            {/*<div className="table-field-recap">
                                <div className="field-title"><span>Fin</span></div>
                                <div className="field">
                                    {time.timeStart} - {time.timeEnd}
                                </div>
                            </div>
                            <div className="table-field-recap">
                                <div className="field-title"><span>Durée</span></div>
                                <div className="field">
                                    <TimeDuration
                                        timeStart={String(time.timeStart)}
                                        timeEnd={String(time.timeEnd)}
                                    />
                                </div>
                            </div>*/}
                            <div className="table-field-recap">
                                <div className="field-title"><span>Durée</span></div>
                                <div className="field">
                                    {Number.parseFloat(String(calculatePourcentage(metting, time))).toFixed(2)} %
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

           {/* <div className="container-table-view mt-3">
                <div className="container-table-head table-success">
                    Conclusion
                </div>
                <div className="container-table-fields">
                    <div className="container-table-field w-100">
                        <div className="field-title-result">
                            <span className="mr-1">Durée réunion : </span>
                            {metting.timeProofreader?.duration && (
                                <TimeDurationTotale
                                    duration={metting.timeProofreader.duration}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>*/}

            {metting.timeProofreader?.description && (
                <>
                    <u>Commentaire :</u>
                    <p style={{whiteSpace: 'pre-line'}}>{metting.timeProofreader?.description}</p>
                </>
            )}
        </>
    )
}