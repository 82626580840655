import axios from "axios";
import API from "@Redak/Api/api.url";
import TokenStorage from "@Redak/Services/TokenStorage";

const getManagementInitialisation = () => axios.get(
    API.BASE + API.MANAGEMENT.INITIALISATION,
    TokenStorage.getAuthentification()
);

const getProofreaderByString = (value: string) => axios.get(
    API.BASE + API.MANAGEMENT.SEARCH_PROOFREADER + value,
    TokenStorage.getAuthentification()
)

const getProofreaders = (type: string) => axios.get(
    API.BASE + API.MANAGEMENT.GET_ALL_USER_BY_TYPE + type,
    TokenStorage.getAuthentification()
)

export {
    getManagementInitialisation,
    getProofreaderByString,
    getProofreaders
}