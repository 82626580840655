import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";

export function sortByAlphaProofreader(
    arr: Array<ProofreaderType>
): ProofreaderType[] {
    return arr.sort((a: ProofreaderType, b: ProofreaderType) => {
        const x = a.lastname.toLowerCase();
        const y = b.lastname.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    });
}

