import React, {useEffect, useState} from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {RootState} from "@Redak/redux/store";
import {useSelector} from "react-redux";
import {Field, Formik} from "formik";
import * as yup from 'yup';
import Shape from "@Redak/types/Common/Shape";
import FormField from "@Redak/Common/Forms/FormField";
import {StateType} from "@Redak/types/Config/ConfigType";
import {Card, CardContent} from "@Redak/Common/Card";
import moment from "moment";
import {allStoreActions} from "@Redak/redux/store";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import ProofreaderActions from "@Redak/redux/reducers/Proofreader/actions";
import SelectSearch, {SelectSearchOption} from "react-select-search";
import {getMetting, putPlanning, putPlanningProofreader} from "@Redak/Api/Metting/metting.api";
import {useAppDispatch} from "@Redak/redux/hooks";
import SyncLoader from "react-spinners/ClipLoader";
import {getProofreaders} from "@Redak/Api/Management/management.api";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import message from "@Redak/utils/Data";
import {minutesToHHMMForInputTime, transformInputTimeOfNumber} from "@Redak/utils/Time/CalculateTime";

const mettingSchema = yup.object<Shape<MettingType>>({
    startRedak: yup.date().required('La date de début rédacteur est requise.')
        .typeError('La date de début rédacteur n\est pas valide.'),
    endRedak: yup.date().required('La date de début rédacteur est requise.')
        .typeError('La date de fin rédacteur n\est pas valide.'),
    proofreader: yup.string().required('Le rédacteur - rédactrice est requise.'),
    estimateDurationWorkTime: yup.number().required('L\'estimation est requise ou n\'est pas valide.'),
    state: yup.string().required('L\'état est requis.'),
    descriptionRedak: yup.string().nullable(),
});

export function MettingEdit(props: { metting: MettingType }) {
    const {metting} = props;

    const { states } = useSelector<RootState, {
        states: Array<StateType>
    }>((state) => state.config);

    const { sendToast } = allStoreActions.toaster;

    const dispatch = useAppDispatch();
    const { updateMetting } = MettingActions;
    const { pushProofreader } = ProofreaderActions;

    const [isLoading, setIsLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [proofreaders, setProofreaders] = useState(Array<SelectSearchOption>);
    const [initialProofreader, setInitialProofreader] = useState(metting.idProofreader);

    useEffect(() => {
        async function initialization(){
            setInitialized(true);
            let proofs  = await getProofreaders(String(5));
            setProofreaders(proofs.data);
        }
        if(!initialized) initialization().then(() => {});
    });

    const submit = async (form: any) => {
        setIsLoading(true);

        try {
            let formStateProofreader: any = {};
            if(initialProofreader !== form.proofreader){
                formStateProofreader = {
                    stateRedak: `/api/planning-state-redak/${form.state}`,
                    user:  `/api/users/${form.proofreader}`
                }
            }else{
                formStateProofreader = {
                    stateRedak: `/api/planning-state-redak/${form.state}`,
                }
            }

            let formMetting = {
                estimateDurationWorkTime: form.estimateDurationWorkTime,
                startRedakProofreader: form.startRedak,
                endRedakProofreader: form.endRedak,
                descriptionRedak: form.descriptionRedak,
            }

            await putPlanningProofreader(metting.idPlanningWorker, formStateProofreader);

            await putPlanning(metting.id, formMetting);

            let response = await getMetting(metting);
            dispatch(updateMetting(response.data));

            if(initialProofreader !== form.proofreader) {
                dispatch(pushProofreader(response.data.proofreader));
                setInitialProofreader(response.data.idProofreader)
            }

            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.metting.update))
        } catch (e) {
            dispatch(sendToast(ToastVariants.DANGER, message.toaster.error.metting))
        }

        setIsLoading(false);
    }

    if(!initialized) {
        return <></>
    }

    return (
        <>
            <div className="container-metting-edit">
                <Formik
                    initialValues={{
                        startRedak: moment(metting.startRedak).format('Y-MM-DD'),
                        endRedak: moment(metting.endRedak).format('Y-MM-DD'),
                        estimateDurationWorkTime: metting.estimateDurationWorkTime,
                        descriptionRedak: metting.descriptionRedak,
                        proofreader: metting.idProofreader,
                        state: metting.state?.id,
                    }}
                    validationSchema={mettingSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={submit}
                >
                    {
                        ({handleSubmit, errors, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Card>
                                    <CardContent title={'Informations générales :'}>
                                        <>
                                            <FormField error={errors.proofreader} label={"Rédacteur - rédactrice :"} name={'proofreader'}>
                                                <SelectSearch
                                                    placeholder={'Sélectionner un rédacteur - rédactrice'}
                                                    onChange={(e) => setFieldValue('proofreader', e)}
                                                    value={String(values.proofreader)}
                                                    options={proofreaders}
                                                    multiple={false}
                                                    search={false}
                                                />
                                            </FormField>

                                            <FormField error={errors.state} label={"État de la réunion :"} name={'state'}>
                                                <Field as="select" name="state" className="custom-select">
                                                    {states.map((option: StateType, index: number) => (
                                                        <option
                                                            value={option.id}
                                                            key={index}
                                                        >
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </FormField>

                                            <div className="form-row">
                                                <FormField
                                                    error={errors.startRedak} label={"Date de début :"}
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    name={'startRedak'}
                                                >
                                                    <Field
                                                        name={'startRedak'} type="date"
                                                        value={values.startRedak}
                                                        className="form-control"
                                                    />
                                                </FormField>

                                                <FormField
                                                    error={errors.endRedak} label={"Date de fin :"}
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    name={'endRedak'}
                                                >
                                                    <Field
                                                        name={'endRedak'} type="date"
                                                        value={values.endRedak}
                                                        className="form-control"
                                                    />
                                                </FormField>

                                                <FormField
                                                    error={errors.estimateDurationWorkTime} label={"Estimation (hh:mm) :"}
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    name={'estimateDurationWorkTime'}
                                                >
                                                    <Field
                                                        value={minutesToHHMMForInputTime(values.estimateDurationWorkTime)}
                                                        onChange={(e: any) => setFieldValue(
                                                            'estimateDurationWorkTime',
                                                            transformInputTimeOfNumber(
                                                                e.target.value
                                                            )
                                                        )}
                                                        name={'estimateDurationWorkTime'}
                                                        className="form-control"
                                                        type="time"
                                                    />
                                                </FormField>
                                            </div>

                                            <FormField error={errors.proofreader} label={"Commentaire :"} name={'descriptionRedak'}>
                                                <textarea
                                                    onChange={(e) => setFieldValue('descriptionRedak', e.target.value)}
                                                    value={values.descriptionRedak ?? undefined}
                                                    style={{resize: 'vertical'}}
                                                    className="form-control"
                                                    name="descriptionRedak"
                                                    maxLength={255}
                                                    rows={2}>
                                                </textarea>
                                            </FormField>
                                        </>
                                    </CardContent>
                                </Card>

                                <div className="form-group d-flex justify-content-center w-100">
                                    <button
                                        className={'btn btn-block btn btn-primary btn-rounded mt-3'}
                                        style={{maxWidth: 200, height: 40}}
                                        disabled={isLoading}
                                        type={'submit'}
                                    >
                                        {
                                            isLoading ? (
                                                <SyncLoader color={'#fff'} loading={true} size={23}/>
                                            ) : (
                                                'Enregistrer'
                                            )
                                        }
                                    </button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
        </>
    );
}

export default MettingEdit;