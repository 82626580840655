import {CustomerType} from "@Redak/types/Customer/CustomerType";

export enum CustomerActionType {
    SET_CUSTOMERS = 'SET_CUSTOMERS'
}

export interface ICustomerBaseActions {
    type: CustomerActionType,
    payload?: any
}

const setCustomers = (customers: Array<CustomerType>) => (
    { type: CustomerActionType.SET_CUSTOMERS, payload: customers }
)

export default {
    setCustomers
}