export enum ToasterActions {
    SEND_TOAST = 'SEND_TOAST',
    REMOVE_ONE_TOAST = 'REMOVE_ONE_TOAST',
    REMOVE_TOAST = 'REMOVE_TOAST'
}

export interface IToasterBaseActions {
    type: ToasterActions,
    payload?: any
}

const sendToast = (type: string, message: string, delay?: number) => (
    { type: ToasterActions.SEND_TOAST, payload: { type, message, delay } }
);

const removeOneToast = (uuid: string) => (
    { type: ToasterActions.REMOVE_ONE_TOAST, payload: uuid }
);

export default {
    sendToast,
    removeOneToast,
};
