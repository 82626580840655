const BASE_URL = process.env.REACT_APP_BASE_URL
const API_BASE_URL = `${BASE_URL}${process.env.REACT_APP_BASE_API}`;

const API = {
    BASE: API_BASE_URL,
    AUTHENTIFICATION: 'login',
    LOST_PASSWORD: 'reset-password',
    USER: {
        CURRENT: 'me'
    },
    MAILER: {
        POST: 'mailer-pool/notification/redak'
    },
    MANAGEMENT: {
        INITIALISATION: 'plannings-proofreaders',
        SEARCH_PROOFREADER: 'search/proofreader/toraka/',
        GET_ALL_USER_BY_TYPE: 'users/type/'
    },
    PROOFREADER: {
        GET_METTINGS_BY_USER: 'plannings/user'
    },
    METTING: {
        BY_ID:  'plannings-proofreaders',
        BY_USER: 'plannings/user',
        PUT: 'plannings/redak',
        PUT_WORKER: 'planning-workers/redak'
    },
    WORK_TIME: {
        POST: 'planning-work-time',
        PUT: 'planning-work-time',
        GET: 'planning-work-time',
        TIME_ENTRY: {
            GET: 'planning-work-time-entry'
        }
    },
    CONFIG: {
        STATE: 'planning-state-redak'
    }
};

export default API;
