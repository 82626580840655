import axios, { AxiosRequestConfig } from 'axios';
import {getCurrentUser} from "@Redak/Api/User/user.api";

export class TokenStorage {
    private static KEY_TOKEN = 'token';
    private static TOKEN: string | null = localStorage.getItem(TokenStorage.KEY_TOKEN);

    public static isAuthenticated(): boolean {
        return this.getToken() !== null;
    }

    public static getAuthentification(): AxiosRequestConfig {
        return {
            headers: { Authorization: `Bearer ${this.getToken()}` }
        };
    }

    private static getToken(): string | null {
        if (this.TOKEN === null) {
            return null;
        }

        return this.TOKEN;
    }

    public static async isTokenValide(): Promise<string | null> {
        let status = null;

        let response = await getCurrentUser();
        if(response.status === 200) {
            return this.TOKEN;
        }

        return status;
    }

    public static storeToken(token: string): void {
        localStorage.setItem(TokenStorage.KEY_TOKEN, token);
        this.TOKEN = token;
    }

    public static clear(): void {
        localStorage.removeItem(TokenStorage.KEY_TOKEN!);
        this.TOKEN = null;
    }
}

export default TokenStorage;