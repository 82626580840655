import React, {ReactElement} from 'react';
import '../../Styles/breadcrumb.css';
import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";
import {NavLink} from "react-router-dom";
import ROUTE from "@Redak/Services/navigation.all";
import {sortByAlphaProofreader} from "@Redak/utils/Filters/OrderData";

export const Navbar = (props: { children: ReactElement | ReactElement[] }) => {
    const { children } = props;
    return (
        <div className="scroll-sidebar">
            <nav className="sidebar-nav">
                <ul id="sidebarnav">
                    <li>
                        {children}
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export function NavItem(props: { children: ReactElement }) {
    const {children} = props;
    return (
        <>
            {children}
        </>
    );
}

export function NavItemListProofreader(props: {
    proofreaders: Array<ProofreaderType>
}) {
    const path = window.location.pathname.slice(0, 20);
    const {proofreaders} = props;
    return (
        <ul className={`collapse ${path === '/management/calendar' && 'in'}`}
            style={{paddingLeft: 5}}
            aria-expanded="false"
        >
            {sortByAlphaProofreader(proofreaders).map((proofreader: ProofreaderType, index: Number) => (
                <li key={String(index)} style={{display: 'flex', alignItems: 'center'}}>
                    <NavLink className={(navdata) => (
                            `calendar-events ${navdata.isActive && 'active'}`
                        )}
                        to={`${ROUTE.MANAGEMENT.CALENDAR}/${proofreader.id}`}
                        style={{cursor: 'pointer', fontSize: 13}}
                    >
                        {proofreader.lastname.toUpperCase()} {proofreader.firstname}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
}