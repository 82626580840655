import React, {ReactElement} from "react";

export function Container(props: { children: ReactElement }) {
    const { children } = props;
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="calender-sidebar" id="card-body-container">
                    {children}
                </div>
            </div>
        </div>
    );
}