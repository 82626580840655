import axios from "axios";
import API from "@Redak/Api/api.url";
import TokenStorage from "@Redak/Services/TokenStorage";

const postWorkTime = (form: any) => axios.post(
    API.BASE + API.WORK_TIME.POST, form,
    TokenStorage.getAuthentification(),
)

const putWorkTime = (id: number, form: any) => axios.put(
    API.BASE + API.WORK_TIME.PUT + `/${id}`, form,
    TokenStorage.getAuthentification()
)

export {
    postWorkTime,
    putWorkTime
}