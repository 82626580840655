import {UserType} from "@Redak/types/User/UserType";

export enum UserActionType {
    SET_USER = 'SET_USER',
    SET_INITIALIZED = 'SET_INITIALIZED',
}

export interface IUserBaseActions {
    type: UserActionType,
    payload?: any
}

const setUser = (user: UserType) => (
    { type: UserActionType.SET_USER, payload: user }
)

const setInitialized = (state: boolean) => (
    { type: UserActionType.SET_INITIALIZED, payload: state }
)

export default {
    setUser,
    setInitialized
}