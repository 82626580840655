import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "@Redak/utils/axiosBaseQuery";
import API from "@Redak/Api/api.url";

const emptySplitApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: API.BASE,
    }),
    reducerPath: 'cditctranscritApi',
    endpoints: () => ({}),
});

export default emptySplitApi;