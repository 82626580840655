import {MettingType} from "@Redak/types/Metting/MettingType";
import * as yup from 'yup';
import Shape from "@Redak/types/Common/Shape";
import {MailerType} from "@Redak/types/Mailer/MailerType";
import {Formik} from "formik";
import {Card, CardContent} from "@Redak/Common/Card";
import FormField from "@Redak/Common/Forms/FormField";
import {Select, SelectOptionType} from "@Redak/Common/Select";
import React, {useState} from "react";
import {useAppDispatch} from "@Redak/redux/hooks";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import {allStoreActions} from "@Redak/redux/store";
import SyncLoader from "react-spinners/ClipLoader";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import message from "@Redak/utils/Data";
import {postMailer} from "@Redak/Api/Mailer/mailer.api";
import {AxiosResponse} from "axios";

const mailerSchema = yup.object<Shape<MailerType>>({
    type:  yup.string().required('L\'objet est requise.'),
    content: yup.string().nullable(),
    note: yup.string().nullable(),
});

const options: SelectOptionType[] = [
    {
        value: 'SEND_METTING_PROOFREADER_REDAK_01', label: 'Nouvelle mission'
    },
    {
        value: 'SEND_METTING_PROOFREADER_REDAK_02', label: 'Mise en ligne des fichiers pour votre mission'
    }
]

export function MailerEdit(props: {metting: MettingType, handleClose?: Function}) {
    const {metting, handleClose} = props;

    const [isLoading, setIsLoading] = useState(false);
    const { sendToast } = allStoreActions.toaster;

    const dispatch = useAppDispatch();
    const { pushMailerInMetting } = MettingActions;

    const submit = async (form: any, action: any) => {
        setIsLoading(true);

        let object: string = "";
        options.map((option) => {
            if(form.type === option.value) object = option.label
        });

        try {
            let formMailer = {
                ...form,
                object: object,
                planning: '/api/plannings/' + metting.id,
            }
            let response: AxiosResponse = await postMailer(formMailer);
            let mailers = metting.mailers; mailers.unshift(response.data)
            dispatch(pushMailerInMetting(metting, mailers));
            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.mailer.create, 7000))
            if(handleClose !== undefined) {
                handleClose()
            }
            setIsLoading(false);
        }catch (error: any) {
            if(error?.response?.status === 424) {
                let data = error?.response?.data;
                dispatch(sendToast(ToastVariants.DANGER, data.message , 7000))
            }else{
                dispatch(sendToast(ToastVariants.DANGER, message.toaster.error.mailer, 7000))
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="container-metting-edit">
                <Formik
                    initialValues={{
                        type: options[0].value,
                        content: '',
                        note: ''
                    }}
                    validationSchema={mailerSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={submit}
                >
                    {
                        ({handleSubmit, handleChange, errors, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Card>
                                    <CardContent title={'Nouveau message :'} >
                                        <>
                                            <FormField
                                                error={errors.type}
                                                label={'Objet du message :'}
                                            >
                                                <Select
                                                    setFieldValue={setFieldValue}
                                                    defaultValue={values.type}
                                                    options={options}
                                                    name={'type'}
                                                />
                                            </FormField>
                                            <FormField
                                                label={'Note interne :'}
                                                error={errors.note}
                                                name={'note'}
                                            >
                                                <textarea
                                                    onChange={(e) => setFieldValue('note', e.target.value)}
                                                    style={{resize: 'vertical'}}
                                                    className="form-control"
                                                    rows={2}
                                                ></textarea>
                                            </FormField>
                                            <FormField
                                                label={'Corps de l\'email complémentaire :'}
                                                error={errors.content}
                                                name={'content'}
                                            >
                                                <textarea
                                                    onChange={(e) => setFieldValue('content', e.target.value)}
                                                    style={{resize: 'vertical'}}
                                                    className="form-control"
                                                    rows={3}
                                                ></textarea>
                                            </FormField>
                                        </>
                                    </CardContent>
                                </Card>
                                <div className="form-group d-flex justify-content-center w-100">
                                    <button
                                        className={'btn btn-block btn btn-primary btn-rounded mt-3'}
                                        style={{maxWidth: 200, height: 40}}
                                        disabled={isLoading}
                                        type={'submit'}
                                    >
                                        {
                                            isLoading ? (
                                                <SyncLoader color={'#fff'} loading={true} size={23}/>
                                            ) : (
                                                'Enregistrer'
                                            )
                                        }
                                    </button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}