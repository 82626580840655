import React from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import moment from "moment";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {minutesToHHMM} from "@Redak/utils/Time/CalculateTime";
import {useAppDispatch} from "@Redak/redux/hooks";
import {MailerList} from "@Redak/Common/Mailer/View/MailerList";

export const CardMettingInformation = (props: {
    metting: MettingType
}) => {
    const {metting} = props;

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const handleShowMailers = () => {
        dispatch(
            push(
                <MailerList mailers={metting.mailers} />, {
                    title: `E-mail envoyés ${metting.buisness}`,
                    className: '',
                    type: 'update'
                }
            )
        );
    }

    return (
        <div className="container-metting-show">
            <div className="container-metting-information">
                <h5>Informations générales :</h5>
                <div className="sub-container-metting-information">
                    <i className=" fas fa-clock"></i>
                    <span>Date réunion :</span> {moment(metting.start).format('LL')} de {moment(metting.start).format('LT')} à {moment(metting.end).format('LT')}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-address-card"></i>
                    <span>Client :</span> {metting.customer.fullname}
                </div>
                <div className="sub-container-metting-information">
                    <i className=" fas fa-location-arrow"></i>
                    <span>Adresse :</span> {metting.location}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-user"></i>
                    <span>Rédacteur :</span> {metting.proofreader.fullname}
                </div>
                <div className="sub-container-metting-information">
                    <i style={{color: metting.state?.colorCode === null ? '#000' : metting.state?.colorCode}}
                       className="fas fa-circle"
                    ></i>
                    <span>État :</span> {metting.state?.name}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar"></i>
                    <span>Date rédacteur :</span>
                    {
                        metting.startRedak && metting.endRedak ? (
                            ' ' + moment(metting.startRedak).format('LL') + ' au ' + moment(metting.endRedak).format('LL')
                        ) : ' Aucune date disponible'
                    }
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-clock"></i>
                    <span>Temps réel relecture :</span> {minutesToHHMM(metting.estimateDurationWorkTime)}
                </div>
                {metting.mailers.length ? (
                    <div className="sub-container-metting-information">
                        <i className="fas fa-envelope"></i>
                        <span>E-mail :</span> {metting.mailers.length} message{metting.mailers.length > 1 ? 's - ' : ' - '}
                        <em style={{textDecoration: 'underline', cursor: 'pointer'}}
                            onClick={handleShowMailers}
                        >
                            Afficher
                        </em>
                    </div>
                ) : <></>}
                <div className="sub-container-metting-information">
                    <span>Commentaire :</span> <br />
                    <span style={{whiteSpace: 'pre-line', textDecoration: 'none', fontWeight: 'normal'}}>
                        {metting.descriptionRedak ? metting.descriptionRedak : 'Aucun commentaire.'}
                    </span>
                </div>
            </div>
        </div>
    )
}