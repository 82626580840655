import axios from "axios";
import API from "@Redak/Api/api.url";
import {IAuthentificationType} from "@Redak/types/Security/AuthentificationType";
import TokenStorage from "@Redak/Services/TokenStorage";

const authentification = (data: IAuthentificationType) => axios.post(
    API.BASE + API.AUTHENTIFICATION, data
);

const getCurrentUser = () => axios.get(
    API.BASE + API.USER.CURRENT,
    TokenStorage.getAuthentification()
)

const sendPassword = (form: {login: string}) => axios.post(
    API.BASE + API.LOST_PASSWORD, form
);

export {
    authentification,
    getCurrentUser,
    sendPassword,
}