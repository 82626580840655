import TokenStorage from "@Redak/Services/TokenStorage";
import {NavLink, redirect} from "react-router-dom";
import ROUTE from "@Redak/Services/navigation.all";
import userActions from "@Redak/redux/reducers/User/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import {allStoreActions, RootState} from "@Redak/redux/store";
import {useSelector} from "react-redux";
import {UserType} from "@Redak/types/User/UserType";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {useState} from "react";

export const Header = () => {

    const { user } = useSelector<RootState, {
        user: UserType
    }>((state) => state.user);

    const mettingsCopy = useSelector<RootState, {
        mettingsCopy: MettingType[]
    }>((state) => state.mettingsCopy);

    const mettings = useSelector<RootState, {
        mettings: MettingType[]
    }>((state) => state.mettings);

    const [search, setSearch] = useState('');

    const dispatch = useAppDispatch();

    const handleChangeSearch = (value: string) => {
        setSearch(value); // Insertion de la valeur dans le state
        if(value.length > 2) {
            let results: Array<MettingType> =  mettings.mettings.filter((item) => item.buisness.toUpperCase()
                .indexOf(value.toUpperCase()) > -1
            );
            dispatch(allStoreActions.mettingsCopy.setMettingsCopy(results));
        }else{
            dispatch(allStoreActions.mettingsCopy.setMettingsCopy(mettings.mettings))
        }
    }

    const handleLogout = () => {
        TokenStorage.clear();
        dispatch(userActions.setInitialized(false));
        return redirect(ROUTE.SECURITY.AUTHENTIFICATION);
    }

    const handleToRedirect = () => {
        if(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_OPERATEUR'))
            return ROUTE.MANAGEMENT.CALENDAR;
        else
            return ROUTE.PROOFREADER.METTINGS;
    }

    return (
        <header className="topbar">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                <div className="navbar-header" style={{margin: 0, padding: 0}}>
                    <NavLink
                        className={'navbar-brand'}
                        to={handleToRedirect()}
                    >
                        <img src={require('../../asset/images/logo.png')}
                             className="light-logo"
                             alt="Logo"
                        />
                    </NavLink>
                </div>
                <div className="navbar-collapse">
                    {user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_OPERATEUR') ? (
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <div className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark">
                                    <i className="ti-menu"></i>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark">
                                    <i className="fas fa-bars" style={{color: "#000"}}></i>
                                </div>
                            </li>
                                <li className="nav-item">
                                    <div className="app-search d-none d-md-block d-lg-block">
                                        <input type="text" className="form-control" placeholder="Rechercher une reunion"
                                               onChange={(e) => handleChangeSearch(e.target.value)}
                                               value={search}
                                        />
                                    </div>
                                </li>
                        </ul>
                    ) : (
                        <ul className="navbar-nav mr-auto"></ul>
                    )}
                    <ul className="navbar-nav my-lg-0">
                        <li className="nav-item dropdown u-pro">
                            <div className="nav-link dropdown-toggle waves-effect waves-dark profile-pic"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                               style={{color: "#000"}}
                            >
                                    <span className="hidden-md-down" style={{fontWeight: 400}}>
                                        {user.lastname.toUpperCase()}
                                        <i className="fa fa-angle-down ml-2"></i>
                                    </span>
                            </div>
                            <div className="dropdown-menu dropdown-menu-right animated flipInY">
                                <button className="dropdown-item" onClick={handleLogout}>
                                    <i className="fa fa-power-off"></i> Déconnexion
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}