import React, {ReactElement, useRef, useState} from 'react';

export function Modal(
    props: {
        children: ReactElement,
        identification: string,
        close: Function,
        className: string,
        title: string,
        type?: string,
    }
) {
    const {
        children, close, title, className,
        type, identification
    } = props;

    const [modalTitle, setModalTitle] = useState(title);
    const modalRef = useRef<HTMLDivElement>(null);

    setTimeout(() => {
        modalRef.current?.classList.add('is-open-modal')
    }, 300);

    const handleClose = () => {
        modalRef.current?.classList.remove('is-open-modal');
        modalRef.current?.classList.add('is-close-modal');
        setTimeout(() => {
            close();
        }, 1000);
    }

    return (
        <div className={`modal-child-content ${className}`} ref={modalRef}>
            <div className="modal-child-header">
                <div className="modal-child-title">
                    <h3>{modalTitle}</h3>
                </div>
                {type === 'update' && (
                    <i className="fas fa-times-circle"
                       onClick={handleClose}
                    ></i>
                )}
            </div>
            <div className="modal-child-sub-content">
                {React.cloneElement(children, {
                    ...children.props,
                    handleClose: handleClose
                })}
            </div>
        </div>
    );
}

export default Modal;