import React, {useState} from "react";
import {Field, Formik} from "formik";
import FormField from "@Redak/Common/Forms/FormField";
import {NavLink, redirect} from "react-router-dom";
import SyncLoader from "react-spinners/ClipLoader";
import * as yup from "yup";
import Shape from "@Redak/types/Common/Shape";
import {IResetPasswordType} from "@Redak/types/Security/ResetPasswordType";
import ROUTE from "@Redak/Services/navigation.all";
import {useAppDispatch} from "@Redak/redux/hooks";
import {allStoreActions} from "@Redak/redux/store";
import {sendPassword} from "@Redak/Api/User/user.api";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import message from "@Redak/utils/Data";

const formSchema = yup.object<Shape<IResetPasswordType>>({
    login: yup.string().required('L\'identifiant est requis.'),
});

export function LostPassword() {
    const [state, setState] = useState<{ isLoading: boolean }>({
        isLoading: false
    });

    const dispatch = useAppDispatch();
    const { sendToast } = allStoreActions.toaster;

    const submit = async (form: any, action: any) => {
        setState((prevState) =>
            ({...prevState, isLoading: true})
        );

        sendPassword(form).then((response) => {
            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.resetPassword))
             setState((prevState) =>
                ({...prevState, isLoading: false})
            );
            return redirect(ROUTE.SECURITY.AUTHENTIFICATION);
        }).catch((err) => {
            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.resetPassword))
             setState((prevState) =>
                ({...prevState, isLoading: false})
            );
            return redirect(ROUTE.SECURITY.AUTHENTIFICATION);
        });
    }

    return (
        <Formik initialValues={{login: ''}}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={submit}
        >
            {
                ({handleSubmit, errors}) => (
                    <form className="form-horizontal form-material" onSubmit={handleSubmit}>

                        <h3 className="text-center mb-3">Espace de connexion</h3>
                        <p className="text-muted">Veuillez saisir ci-dessous un votre adresse e-mail pour recevoir un nouveau mot de passe ! </p>

                        <FormField error={errors.login}>
                            <Field placeholder={'Adresse e-mail'}
                                   className="form-control"
                                   maxLength={255}
                                   name="login"
                                   type="text"
                            />
                        </FormField>

                        <div className="form-group text-center">
                            <div className="col-xs-12">
                                <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                                        disabled={state.isLoading}
                                        type="submit"
                                >
                                    {
                                        state.isLoading ? (
                                            <SyncLoader color={'#fff'} loading={true} size={23}/>
                                        ) : (
                                            "Réinitialiser"
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="form-group text-center m-t-0">
                            <div className="col-xs-12">
                                <NavLink className="text-muted" to={ROUTE.SECURITY.AUTHENTIFICATION}>
                                    Annuler
                                </NavLink>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    )
}

export default LostPassword;