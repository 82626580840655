import {MettingType} from "@Redak/types/Metting/MettingType";
import moment from "moment";
import React from "react";
import message from "@Redak/utils/Data";
import {ProgressBar} from "@Redak/Common/ProgressBar";
import {calculateTimeProgressBar} from "@Redak/utils/Time/CalculateTime";

export const CardMetting = (
    props: {
        handleClosingEntryHours?: Function
        handleEditEntryHours?: Function
        isProgressBar?: boolean,
        metting: MettingType,
        isAction: boolean,
        className?: string
    }
) => {
    const {
        handleEditEntryHours, handleClosingEntryHours, metting,
        isAction, className, isProgressBar = true
    } = props;
    return (
        <div className={`card ${className}`} style={{overflow: "hidden"}}>
            <div className="card-header">
                <b>
                    Réunion du {moment(metting.start).format('ll') }&nbsp;
                    de {moment(metting.start).format('LT')}&nbsp;
                    à {moment(metting.end).format('LT')}
                </b>
            </div>
            {isProgressBar && <ProgressBar number={calculateTimeProgressBar(metting)} />}
            <div className="card-body">
                <h5 className="card-title text-dark">{metting.buisness.toUpperCase()}</h5>
                <p className="card-text">
                    <i className="fas fa-map-pin mr-2"></i>
                    {metting.location ? metting.location : 'Aucune adresse disponible'}
                </p>
                <p className="card-text">
                    <i className="fas fa-calendar mr-2"></i>
                    {metting.startRedak && metting.end ? (
                       'Du ' + moment(metting.startRedak).format('LL') + ' au ' + moment(metting.endRedak).format('LL')
                    ) : 'Aucune adresse disponible'}
                </p>
                {metting.state?.name && (
                    <p className="card-text">
                        <i style={{color: metting.state?.colorCode === null ? '#000' : metting.state?.colorCode}}
                           className="fas fa-circle mr-2"
                        ></i>
                        {metting.state?.name}
                    </p>
                )}

                {isAction && (
                    <>
                        {!metting.timeProofreader?.isFinished ? (
                            <div className="d-flex flex-wrap">
                                <button
                                    onClick={() => handleEditEntryHours && handleEditEntryHours(metting)}
                                    className="btn btn-primary mr-3 mt-2"
                                >
                                    {message.proofreader.button.editEntryHours}
                                </button>
                                {metting.timeProofreader && (
                                    <button
                                        onClick={() => handleClosingEntryHours && handleClosingEntryHours(metting)}
                                        className="btn btn-success mt-2 w-sm-100"
                                    >
                                        {message.proofreader.button.closing}
                                    </button>
                                )}
                            </div>
                        ): (
                            <button
                                onClick={() => handleClosingEntryHours && handleClosingEntryHours(metting)}
                                className="btn btn-info"
                            >
                                {message.proofreader.button.details}
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}