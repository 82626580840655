import {StateType} from "@Redak/types/Config/ConfigType";
import {ConfigActionsType, IConfigBaseActions} from "@Redak/redux/reducers/Config/actions";

export interface  ConfigStateType {
    states: Array<StateType>
}

export const initialConfigState: ConfigStateType = {
    states: []
}

export const configReducer = (
    state: any = initialConfigState, action: IConfigBaseActions
) => {
    switch (action.type) {
        case ConfigActionsType.SET_CONFIG: {
            return {
                ...state,
                states: action.payload
            }
        }
        default: {
            return state;
        }
    }
}