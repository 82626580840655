import {MettingType} from "@Redak/types/Metting/MettingType";
import {ISchedule} from "tui-calendar";
import moment from "moment";
import {calculateTimeProgressBar} from "@Redak/utils/Time/CalculateTime";

export function prepareCalendar(mettings: Array<MettingType>): Array<ISchedule> {
    let array: Array<ISchedule> = [];
    mettings.map((metting: MettingType) => {
        if(metting.startRedak && metting.endRedak) {
            let progress = Number.parseFloat(String(calculateTimeProgressBar(metting))).toFixed(2)
            array.push({
                title: `${progress} % / ${metting.proofreader.fullname} / ${metting.buisness} / ${metting.state?.name}`,
                bgColor: metting?.state?.colorCode ? metting.state.colorCode : undefined,
                start: moment(metting.startRedak).toISOString(),
                end: moment(metting.endRedak).add(1, 'day').toISOString(),
                location: metting.location,
                id: String(metting.id),
                category: 'time',
                calendarId: '0',
                attendees: [
                    metting.proofreader.fullname
                ],
                isFocused: false,
                isReadOnly: true,
                color: '#ffffff',
            })
        }
    });

    return array;
}