import {Route, Routes} from "react-router-dom";
import Signing from "@Redak/Screen/Security/Signing";
import LostPassword from "@Redak/Screen/Security/LostPassword";

export function Security() {
    return (
        <section id="wrapper">
            <div className="login-register">
                <div className="login-box card">
                    <div className="card-body">
                        <Routes>
                            <Route path={'/reset-password'} element={<LostPassword/>}/>
                            <Route path={'/signing'} element={<Signing/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Security;