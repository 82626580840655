import React, {ReactElement} from "react";

const Card = (props: { children: ReactElement }) => {
    const {children} = props;
    return (
        <div className="card mb-0">
            <div className="card-body pb-0">
                {children}
            </div>
        </div>
    )
}

const CardContent = (props: {
    children: ReactElement,
    title: string
}) => {
    const { children, title } = props;
    return (
        <>
            <h5 className="card-title">{title}</h5>
            {children}
        </>
    )
}

export {
    CardContent,
    Card
}