import axios from "axios";
import API from "@Redak/Api/api.url";
import TokenStorage from "@Redak/Services/TokenStorage";

const getConfigState = () => axios.get(
    API.BASE + API.CONFIG.STATE,
    TokenStorage.getAuthentification()
)

export {
    getConfigState
}