import {useSelector} from "react-redux";
import {RootState, store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";
import React from "react";
import moment from "moment";
import FilterTable from "@Redak/Screen/Management/Table/FilterTable";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import {StateRound} from "@Redak/Common/StateRound";
import MettingShow from "@Redak/Common/Metting/View/Management/MettingShow";
import {calculateTimeProgressBar} from "@Redak/utils/Time/CalculateTime";

export function Table() {
    useSelector<RootState, {
        proofreaders: ProofreaderType[],
    }>((state) => state.proofreadersCopy);

    useSelector<RootState, {
        mettings: MettingType[],
    }>((state) => state.mettingsCopy);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const onLoadMettingsByProofreader = (proofreader: ProofreaderType, index: Number): Array<MettingType> => {
        let mettingsProofreader: Array<MettingType> = [];
        store.getState().mettingsCopy.mettingsCopy.forEach((metting: MettingType) => {
            if(metting.idProofreader === proofreader.id) {
                mettingsProofreader.push(metting);
            }
        });

        let table = document.getElementById('table_' + index);
        if (table !== null) {
            if (!mettingsProofreader.length) {
                table.style.display = "none";
            } else {
                table.style.display = "contents";
            }
        }

        return mettingsProofreader;
    }

    const handleShow = (metting: MettingType) => {
        dispatch(
            push(
                <MettingShow initialMetting={metting} />, {
                    title: metting.buisness,
                    className: '',
                    type: 'update'
                }
            )
        );
    }

    const displayDate = (d: string|null): string =>{
        if(!moment(d).isValid()) {
            return '--'
        }
        return moment(d).format('DD-MM-YYYY');
    }

    return (
        <div className="container-table">
            <div className="card" style={{ background: '#F1C40E' }}>
                <FilterTable />
            </div>

            {
                !store.getState().proofreaders.proofreadersCopy.length ? (
                    <p className="text-center">Aucune réunion disponible</p>
                ) : (
                    <table className="table--primary table table-hover table-responsive-sm">
                        <thead>
                        <tr>
                            <th className="invisible"></th>
                            <th className="classic left" scope="col">Nom & Prénom</th>
                            <th className="classic right" scope="col">E-mail</th>
                            <th className="invisible"></th>
                        </tr>
                        </thead>
                        {
                            store.getState().proofreaders.proofreadersCopy.map((proofreader: ProofreaderType, i: Number) => (
                                <tbody key={String(i)} id={'table_' + i}>
                                <tr className="classic">
                                    <td className="invisible"></td>
                                    <td className="classic left">{proofreader.firstname} {proofreader.lastname.toUpperCase()}</td>
                                    <td className="classic right">{proofreader.email?.toLowerCase()}</td>
                                    <td className="invisible"></td>
                                </tr>
                                <tr>
                                    <td className="invisible"></td>
                                    <td colSpan={2} style={{ padding: 0 }}>
                                        <table className="table--secondary" style={{ paddingLeft: 0 }}>
                                            <thead>
                                            <tr>
                                                <th colSpan={3} className="classic bottom right left">Informations</th>
                                                <th colSpan={6} className="classic bottom right">Rédacteur</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={1} className="classic left">ID</th>
                                                <th className="classic right">Société</th>
                                                <th className="classic right">Date réunion (cditctranscrit)</th>
                                                <th className="classic">Début</th>
                                                <th className="classic right">Fin</th>
                                                <th colSpan={2} className="classic">État</th>
                                                <th className="classic left right">%</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                onLoadMettingsByProofreader(proofreader, i).map((metting: MettingType, index: Number) => (
                                                    <tr key={String(index)} className="data"
                                                        onClick={() => handleShow(metting)}
                                                    >
                                                        <td className="classic identified">
                                                            {metting.id}
                                                        </td>
                                                        <td className="buisness">
                                                            {metting.buisness}
                                                        </td>
                                                        <td className="classic text-left">
                                                            {moment(metting.start).format('LL')} de {moment(metting.start).format('LT')} à {moment(metting.end).format('LT')}
                                                        </td>
                                                        <td className="classic">
                                                            {displayDate(metting.startRedak)}
                                                        </td>
                                                        <td className="classic">
                                                            {displayDate(metting.endRedak)}
                                                        </td>
                                                        <td className="state">{metting.state?.name}</td>
                                                        <td className="state-proofreader right">
                                                            <StateRound metting={metting} />
                                                        </td>
                                                        <td className="pourcentage">
                                                            {`${Number.parseFloat(String(calculateTimeProgressBar(metting))).toFixed(2)} %`}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="invisible"></td>
                                </tr>
                                </tbody>
                            ))
                        }
                    </table>
                )
            }
        </div>
    )
}

export default Table;