import React, {useEffect, useState} from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import {findMettingById} from "@Redak/utils/Metting/findMetting";
import {useSelector} from "react-redux";
import {Alert} from "@Redak/Common/Alert";
import {AlertVariants} from "@Redak/Common/Alert/type";
import message from "@Redak/utils/Data";
import MettingEdit from "@Redak/Common/Metting/View/Management/MettingEdit";
import {CardMettingInformation} from "@Redak/Common/Metting/Card/CardMettingInformation";
import {TimeWorkTableDetails} from "@Redak/Common/Metting/View/Proofreader/Table/TimeWorkTableDetails";
import MettingEntryHoursEdit from "@Redak/Common/Metting/View/Proofreader/MettingEntryHoursEdit";
import {ProgressBar} from "@Redak/Common/ProgressBar";
import {calculateTimeProgressBar} from "@Redak/utils/Time/CalculateTime";
import {MailerEdit} from "@Redak/Common/Mailer/View/MailerEdit";

export function MettingShow(props: { initialMetting: MettingType }) {
    const { initialMetting } = props;

    const modals = useSelector((state: any) => state.modals.modals);
    const [isLoading, setIsLoading] = useState(true);
    const [metting, setMetting] = useState(Object);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const init = async () => {
        setMetting(await findMettingById(initialMetting));
    }

    useEffect(() => {
        init().then(() => setIsLoading(false));
    }, [modals.length])

    const handleEditMetting = () => {
        dispatch(
            push(
                <MettingEdit metting={metting} />, {
                    title: `Éditer - ${metting.buisness}`,
                    className: '',
                    type: 'update'
                }
            )
        );
    }

    const handleEditTime = () => {
        dispatch(
            push(
                <MettingEntryHoursEdit metting={metting} isManagement={true} />, {
                    title: `Édition index - ${metting.buisness}`,
                    className: '',
                    type: 'update'
                }
            )
        )
    }

    const handleEditMailer = () => {
        dispatch(
            push(
                <MailerEdit metting={metting} />, {
                    title: `Notification - ${initialMetting.proofreader.lastname}`,
                    className: '',
                    type: 'update'
                }
            )
        )
    }

    if(isLoading) {
        return <></>;
    }

    return (
        <>
            <div className="container-progress">
                <ProgressBar
                    number={calculateTimeProgressBar(metting)}
                />
            </div>

            {metting.startRedak === null || metting.endRedak === null ? (
                <Alert
                    alert={{
                        message: message.manager.alert.notDateMetting,
                        type: AlertVariants.DANGER,
                    }}
                />
            ) : metting.state?.value === 'STATE_DEFAULT' ? (
                <Alert alert={{
                        message: message.manager.warning.statusDefault,
                        type: AlertVariants.WARNING
                    }}
                />
            ) : <></>}

            <CardMettingInformation
                metting={metting}
            />

            {metting.timeProofreader?.times?.length ? (
                <>
                    <hr /><TimeWorkTableDetails metting={metting} />
                </>
            ): <></>}

            <hr />

            <div className="d-flex justify-content-center">
                <button className={'btn btn-success mx-2'} onClick={handleEditMailer}>
                    Envoyer e-mail
                </button>
                <button className={'btn btn-primary mx-2'} onClick={handleEditMetting}>
                    Modifier la réunion
                </button>
                <button className={'btn btn-info mx-2'} onClick={handleEditTime}>
                    Modifier l'index
                </button>
            </div>
        </>
    );
}

export default MettingShow;

