import axios from "axios";
import API from "@Redak/Api/api.url";
import {UserType} from "@Redak/types/User/UserType";
import TokenStorage from "@Redak/Services/TokenStorage";
import {MettingType} from "@Redak/types/Metting/MettingType";

const getMettingsByWorker = (user: UserType) => axios.get(
    API.BASE + API.METTING.BY_USER + `/${user.id}`,
    TokenStorage.getAuthentification()
)

const getMetting = (metting: MettingType) => axios.get(
    API.BASE + API.METTING.BY_ID + `/${metting.id}`,
    TokenStorage.getAuthentification()
)

const getMettingForSubscriberSocket = (id: number) => axios.get(
    API.BASE + API.METTING.BY_ID + `/${id}`,
    TokenStorage.getAuthentification()
)

const getMettingsByProofreader = (user: UserType) => axios.get(
    API.BASE + API.PROOFREADER.GET_METTINGS_BY_USER + `/${user.id}`,
    TokenStorage.getAuthentification()
)

const putPlanningProofreader = (id: number, form: any) => axios.put(
    API.BASE + API.METTING.PUT_WORKER + `/${id}`, form,
    TokenStorage.getAuthentification()
)

const putPlanning = (id: number, form: any) => axios.put(
    API.BASE + API.METTING.PUT + `/${id}`, form,
    TokenStorage.getAuthentification()
)

export {
    getMettingForSubscriberSocket,
    getMettingsByProofreader,
    putPlanningProofreader,
    getMettingsByWorker,
    putPlanning,
    getMetting
}