import {MailerType} from "@Redak/types/Mailer/MailerType";
import moment from "moment";
import React from "react";

export const CardMailer = (props: {mailer: MailerType}) => {
    const {mailer} = props;
    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <b>{mailer.object}</b>
                <span>
                    {moment(mailer.createdAt).format('ll')} à {moment(mailer.createdAt).format('LT')}
                </span>
            </div>
            <div className="card-body">
                <p className="card-text">
                    <i className="fas fa-envelope mr-1"></i>
                    <b><u>E-mail :</u></b> <span>{mailer.email}</span>
                </p>
                {mailer.note && (
                    <p className="card-text">
                        <b><u>Note interne :</u></b> <br/>
                        <span style={{whiteSpace: 'pre-line'}}>
                            {mailer.note}
                        </span>
                    </p>
                )}
                {mailer.content && (
                    <p className="card-text">
                        <b><u>Note interne :</u></b> <br/>
                        <span style={{whiteSpace: 'pre-line'}}>
                            {mailer.content}
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
}