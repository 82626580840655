import {MailerType} from "@Redak/types/Mailer/MailerType";
import {CardMailer} from "@Redak/Common/Mailer/Card/CardMailer";

export const MailerList = (props: {mailers: MailerType[]}) => {
    const {mailers} = props;

    return(
        <>
            {mailers.map((mailer: MailerType, index: number) => (
                <CardMailer
                    key={String(index)}
                    mailer={mailer}
                />
            ))}
        </>
    )
}