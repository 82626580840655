import {allStoreActions} from "@Redak/redux/store";
import {useAppDispatch} from "@Redak/redux/hooks";
import {useEffect, useRef} from "react";

export const ToastCard = (props: {toast: any}) => {
    const { removeOneToast } = allStoreActions.toaster;
    const dispatch = useAppDispatch();
    const {toast} = props;
    const toastRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if(toastRef.current) toastRef.current.classList.add('is-disappearing');
        }, toast.delay - 500);

        setTimeout(() => {
            dispatch(removeOneToast(toast.uuid));
        }, toast.delay)
    }, []);

    return (
        <div className={`app-toast ${toast.type}`} ref={toastRef}>
            <div className="content">
                {toast.message}
            </div>
            {/*<div
                className="actions"
                role="button"
                tabIndex={-1}
                onClick={() => dispatch(removeOneToast(toast.uuid))}
            >
                <i className="fas fa-close" />
            </div>*/}
        </div>
    )

}