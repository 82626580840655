import {MettingType} from "@Redak/types/Metting/MettingType";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import SyncLoader from "react-spinners/ClipLoader";
import {postWorkTime, putWorkTime} from "@Redak/Api/WorkTime/worktime.api";
import {AxiosResponse} from "axios";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import {WorkTimeEntryType} from "@Redak/types/Form/WorkTimeEntryType";
import {allStoreActions} from "@Redak/redux/store";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import message from "@Redak/utils/Data";
import {CardMetting} from "@Redak/Common/Metting/Card/CardMetting";

const timeModel: WorkTimeEntryType = {
    date: moment().format('YYYY-MM-DD'),
    // timeStart: null,
    // timeEnd: null,
    timeCode: '00:00:00',
    // duration: null
}

export function MettingEntryHoursEdit(props: { metting: MettingType, isManagement: boolean }) {
    const {metting, isManagement} = props;

    const [state, setState] = useState<{
        times: WorkTimeEntryType[], isInitialized: boolean,
        isLoading: boolean, description: string,
        mettingClone: MettingType
    }>({
        isInitialized: false, isLoading: false, description: '',
        times: [], mettingClone: metting
    });

    const { sendToast } = allStoreActions.toaster;
    const dispatch = useAppDispatch();
    const { updateMettingWorkTime } = MettingActions;

    useEffect(() => {
        if(metting.timeProofreader !== null) {
            // @ts-ignore
            setState((prevState) => ({
                ...prevState, times: metting.timeProofreader?.times,
                description: metting.timeProofreader?.description,
            }));
        }
        setState((prevState) => ({
            ...prevState, isInitialized: true
        }));
    }, [state.isInitialized]);

    const handleAddTime = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            times: [
                ...prevState.times,
                timeModel
            ],
            isLoading: false
        }));
    }, [state.times]);

    const handleDeleteTime = useCallback((b: WorkTimeEntryType, i: number) => {
        const array = state.times.filter((b: WorkTimeEntryType, index: number) => index !== i);
        setState((prevState) => ({
            ...prevState, times: array
        }));
    }, [state.times]);

    const handleChange = useCallback((value: string, property: string, index: number) => {
        // const timeStart = property === 'timeStart' ? value : state.times[index].timeStart;
        // const timeEnd = property === 'timeEnd' ? value : state.times[index].timeEnd;
        // let minutes: number = 0;

        // if(timeStart !== null && timeEnd !== null && timeStart !== "" && timeEnd !== "") {
        //     let start: Moment = transformInputTimeOfObjectMoment(timeStart);
        //     let end: Moment = transformInputTimeOfObjectMoment(timeEnd);
        //     minutes =  Number(end.diff(start, 'minutes') - 1);
        // }

        setState((prevState) => ({
            ...prevState,
            ...state.times[index] = {
                ...state.times[index],
                [property]: value,
                // duration: minutes
            }
        }));
    }, [state.times]);

    const handleSubmit = async (): Promise<void> => {
        setState((prevState) => ({
            ...prevState, isLoading: true
        }));

        try {
            let response: AxiosResponse;
            if(state.mettingClone.timeProofreader) {
                let array: WorkTimeEntryType[] = [];
                state.times.map((time: WorkTimeEntryType) => {
                    if(time.id)  {
                        let id = '/api/planning-work-time-entry/' + time.id;
                        array.push({...time, id: id});
                    }else{
                        array.push({...time});
                    }
                });

                response = await putWorkTime(state.mettingClone.timeProofreader.id, {
                    // duration: Number(calculateTotalDuration(state.times)),
                    planning: '/api/plannings/' + state.mettingClone.id,
                    description: state.description,
                    times: array
                });
            }else{
                response = await postWorkTime({
                    // duration: Number(calculateTotalDuration(state.times)),
                    planning: '/api/plannings/' + state.mettingClone.id,
                    description: state.description,
                    times: state.times
                });
            }

            dispatch(updateMettingWorkTime(metting, response.data));
            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.time.update));
            setState((prevState) => ({
                ...prevState,
                times: response.data.times,
                mettingClone: {
                    ...prevState.mettingClone,
                    timeProofreader: response.data
                },
                isLoading: false,
            }));
        } catch (e) {
            dispatch(sendToast(ToastVariants.DANGER, message.toaster.error.time, 5000));
            setState((prevState) => ({
                ...prevState, isLoading: false,
            }));
        }
    }

    if(!state.isInitialized) {
        return <></>;
    }

    return(
        <div className="card p-0">
            <CardMetting metting={metting} isAction={false} isProgressBar={false} />
            <div className="card-body">
                <div className="container-table-multiple-edit">
                    <div className="table-head-multiple table-success">
                        Tous les index
                        <div
                            className="add-another-collection-widget text-center"
                            data-list-selector="#breaks-fields-list"
                            style={{ cursor: 'pointer' }}
                            onClick={handleAddTime}
                        >
                            <span className="mr-2">
                                <b>Ajouter un index</b>
                            </span>
                            <i className="fas fa-plus-circle mr-2"></i>
                        </div>
                    </div>
                    <div className="table-fields-name-multiple">
                        <div className="table-field-name-multiple date">Date</div>
                        <div className="table-field-name-multiple time">Time code</div>
                        {/*<div className="table-field-name-multiple time">Début</div>
                        <div className="table-field-name-multiple time">Fin</div>
                        <div className="table-field-name-multiple duration">Durée</div>*/}
                        <div className="table-field-name-multiple action">Action</div>
                    </div>
                    <div>
                        {
                            state.times.length ? (
                                <>
                                    {state.times.map((b: WorkTimeEntryType, index: number) => (
                                        <div key={String(index)} className="container-table-fields">
                                            <div className="table-field date">
                                                <div className="field-title">
                                                    <span>Date</span>
                                                </div>
                                                <div className="field">
                                                    <input
                                                        onChange={(e) => handleChange(e.target.value, 'date', index)}
                                                        value={(moment(b.date).format('YYYY-MM-DD'))}
                                                        disabled={isManagement ? false : !!b.id}
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-field time">
                                                <div className="field-title">
                                                    <span>Time code</span>
                                                </div>
                                                <div className="field">
                                                    <input
                                                        onChange={(e) => handleChange(e.target.value, 'timeCode', index)}
                                                        disabled={isManagement ? false : !!b.id}
                                                        value={String(b.timeCode)}
                                                        className="form-control"
                                                        required={true}
                                                        type="time"
                                                        step="2"
                                                    />
                                                </div>
                                            </div>
                                            {/*<div className="table-field time">
                                                <div className="field-title">
                                                    <span>Début</span>
                                                </div>
                                                <div className="field">
                                                    <input
                                                        onChange={(e) => handleChange(e.target.value, 'timeStart', index)}
                                                        disabled={isManagement ? false : !!b.id}
                                                        value={String(b.timeStart)}
                                                        className="form-control"
                                                        required={true}
                                                        type="time"
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-field time">
                                                <div className="field-title">
                                                    <span>Fin</span>
                                                </div>
                                                <div className="field">
                                                    <input
                                                        onChange={(e) => handleChange(e.target.value, 'timeEnd', index)}
                                                        disabled={isManagement ? false : !!b.id}
                                                        value={String(b.timeEnd)}
                                                        className="form-control"
                                                        required={true}
                                                        type="time"
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-field duration">
                                                <div className="field-title">
                                                    <span>Durée</span>
                                                </div>
                                                <div className="field d-flex justify-content-center align-items-center">
                                                    <TimeDuration
                                                        timeStart={b.timeStart}
                                                        timeEnd={b.timeEnd}
                                                    />
                                                </div>
                                            </div>*/}
                                            <div className="table-field table-field-delete action">
                                                <div className="field-title">
                                                    <span>Action</span>
                                                </div>
                                                <div className="field text-left">
                                                    <button
                                                        onClick={() => handleDeleteTime(b , index)}
                                                        disabled={isManagement ? false : !!b.id}
                                                        className="btn btn-danger"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div
                                        className="add-another-collection-widget text-center table-success py-2"
                                        data-list-selector="#breaks-fields-list"
                                        style={{cursor: 'pointer'}}
                                        onClick={handleAddTime}
                                        id="first-help"
                                    >
                                        <span className="mr-2">
                                            <b>Ajouter le premier index</b>
                                        </span>
                                        <i className="fas fa-plus-circle"></i>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="form-fields-planning form-container-customer">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Commentaire :</label>
                                <textarea
                                    onChange={(e) => {
                                        setState((prevState) => ({
                                            ...prevState, description: e.target.value
                                        }));
                                    }}
                                    style={{resize: 'vertical'}}
                                    className="form-control"
                                    value={state.description}
                                    rows={2}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group d-flex justify-content-center w-100">
                    <button
                        className={'btn btn-block btn btn-primary btn-rounded mt-3'}
                        style={{maxWidth: 200, height: 40}}
                        disabled={state.isLoading}
                        onClick={handleSubmit}
                        type={'button'}
                    >
                        {
                            state.isLoading ? (
                                <SyncLoader color={'#fff'} loading={true} size={23}/>
                            ) : (
                                'Enregistrer'
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MettingEntryHoursEdit;