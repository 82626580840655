import React from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";

export const StateRound = (props: { metting: MettingType }): React.ReactElement => {
    const {metting} = props;

    if (metting.timeProofreader?.isFinished) {
        return (
            <button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="top"
                    title="Terminé"
            >
                <i style={{color: '#00ab13'}} className="fas fa-circle"></i>
            </button>
        )
    }

    if (metting.timeProofreader?.isFinished === false) {
        return (
            <button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="top"
                    title="En cours ..."
            >
                <i style={{color: '#ffca00'}} className="fas fa-circle"></i>
            </button>
        )
    }

    if(metting.endRedak === null || metting.startRedak === null) {
        return (
            <button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="top"
                    title="Réunion incomplète"
            >
                <i style={{color: '#ff0000'}} className="fas fa-exclamation"></i>
            </button>
        )
    }

    return (
        <button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="top"
                title="Aucun temps disponible"
        >
            <i style={{color: '#ff0000'}} className="fas fa-circle"></i>
        </button>
    )
}