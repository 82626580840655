import {ToastVariants} from "@Redak/Common/Toaster/type";
import {IToasterBaseActions, ToasterActions} from "@Redak/redux/reducers/Toaster/actions";
import {Toaster} from "@Redak/Common/Toaster";

export type ToastType = {
    uuid: string,
    type: ToastVariants,
    delay: number,
    message: string
}

export interface InitialToasterStateType {
    toasts: ToastType[],
}

export const initialToasterState: InitialToasterStateType = {
    toasts: []
}

export const toasterReducer = (
    state: InitialToasterStateType = initialToasterState,
    action: IToasterBaseActions
) => {
    switch (action.type) {
        case ToasterActions.SEND_TOAST: {
            const toast = new Toaster(action.payload);
            return {
                ...state,
                toasts: [...state.toasts, toast],
            };
        }
        case ToasterActions.REMOVE_ONE_TOAST: {
            const toasts = [...state.toasts];
            const i: number = toasts.findIndex((toast: any) => toast.uuid === action.payload);
            toasts.splice(i, 1);
            return {
                ...state,
                toasts,
            };
        }
        case ToasterActions.REMOVE_TOAST: {
            return {
                ...state,
                toasts: [],
            };
        }
        default:
            return state;
    }
}