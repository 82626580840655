import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "@Redak/redux/hooks";
import {useSelector} from "react-redux";
import {allStoreActions, RootState} from "@Redak/redux/store";
import {getCurrentUser} from "@Redak/Api/User/user.api";
import userActions from "@Redak/redux/reducers/User/actions";
import {LoaderPage} from "@Redak/Common/Loader";
import {redirect} from "react-router-dom";
import ROUTE from "@Redak/Services/navigation.all";
import TokenStorage from "@Redak/Services/TokenStorage";
import {getManagementInitialisation} from "@Redak/Api/Management/management.api";
import {getConfigState} from "@Redak/Api/Config/config.api";
import {getMettingsByProofreader} from "@Redak/Api/Metting/metting.api";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import message from "@Redak/utils/Data";

export function StoreInitializer(props: { children: React.ReactNode }) {
    const { children } = props;
    const dispatch = useAppDispatch();
    const { sendToast } = allStoreActions.toaster;

    const [isLoading, setIsLoading] = useState(true);

    const isInitialized = useSelector<RootState, boolean>(
        state => state.user.isInitialized
    );

    useEffect(() => {
        async function init() {
            setIsLoading(true);
            let user = await getCurrentUser();
            dispatch(allStoreActions.users.setUser(user.data.user));

            let configState = await getConfigState();
            dispatch(allStoreActions.config.setConfig(configState.data))

            let roles: Array<string> = user.data.user.roles;
            if(roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_OPERATEUR')) {
                let initialisation = await getManagementInitialisation();
                dispatch(allStoreActions.mettings.setMettings(initialisation.data.plannings))
                dispatch(allStoreActions.mettings.setMettingsCopy(initialisation.data.plannings))
                dispatch(allStoreActions.customers.setCustomers(initialisation.data.customers))
                dispatch(allStoreActions.proofreaders.setProofreaders(initialisation.data.proofreaders))
                dispatch(allStoreActions.proofreaders.setProofreadersCopy(initialisation.data.proofreaders))
            }else if (roles.includes('ROLE_PROOFREADER')){
                let mettings = await getMettingsByProofreader(user.data.user);
                dispatch(allStoreActions.mettings.setMettings(mettings.data))
            }else{
                alert('Accès non autorisé !')
            }

            let body = document.getElementById('app');
            if(null !== body) {
                body.removeAttribute('class');
                body.setAttribute('class', 'skin-blue fixed-layout');
            }

            setIsLoading(false);
        }
        if(!isInitialized) {
            init().then(() => {
                dispatch(userActions.setInitialized(true));
            }).catch((error) => {
                if(error?.response?.status === 401) {
                    TokenStorage.clear();
                    setIsLoading(false);
                    dispatch(userActions.setInitialized(true))
                    return redirect(ROUTE.SECURITY.AUTHENTIFICATION);
                } else if(error?.response?.status === 403){
                    TokenStorage.clear();
                    setIsLoading(false);
                    dispatch(userActions.setInitialized(true))
                    dispatch(sendToast(ToastVariants.DANGER, message.toaster.error.accessDenied, 5000))
                    return redirect(ROUTE.SECURITY.AUTHENTIFICATION);
                }
            });
        }
    }, [isInitialized, dispatch]);
    return (
        <>
            {
                isLoading ? (
                    <LoaderPage />
                ) : (
                    <>{isInitialized && children}</>
                )
            }
        </>
    )
}

export default StoreInitializer;