import {store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";

export function findMettingByProofreader(id: string | undefined): Array<MettingType>{
    const mettings: MettingType[] = [];
    if(undefined !== id) {
        store.getState().mettings.mettings.map((metting: MettingType) => {
            if(metting.idProofreader === Number(id))
                mettings.push(metting)
        });
        return mettings;
    }
    return store.getState().mettings.mettingsCopy;
}

export function findMettingById(metting: MettingType): Promise<MettingType> {
    return new Promise(resolve => {
        store.getState().mettings.mettings.map((m: MettingType) => {
            if(m.id === metting.id) {
                resolve(m);
            }
        });
    })
}