import React, {useEffect, useRef, useState} from 'react';
import {MettingType} from "@Redak/types/Metting/MettingType";
import 'tui-calendar/dist/tui-calendar.css';
import moment from "moment";
import 'moment/locale/fr';
import {useAppDispatch} from "@Redak/redux/hooks";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import ToastUIReactCalendar from "@toast-ui/react-calendar";
import {ISchedule} from "tui-calendar";
import {PlanningCalendar} from "@Redak/Common/Calendar/PlanningCalendar";
import {HeaderPlanningCalendar} from "@Redak/Common/Calendar/HeaderPlanningCalendar";
import {useParams} from "react-router-dom";
import {findMettingByProofreader} from "@Redak/utils/Metting/findMetting";
import {useSelector} from "react-redux";
import {RootState} from "@Redak/redux/store";
import MettingShow from "@Redak/Common/Metting/View/Management/MettingShow";
import {prepareCalendar} from "@Redak/utils/Calendar/prepare.calendar";
import {FilterDateMetting} from "@Redak/utils/Filters/FilterDateMetting";

interface initialStateType {
    isLoading: boolean, isInitialized: boolean,
    startRedak: string, endRedak: string,
    heightCalendar: number,
    month: string,
}

const initialState: initialStateType = {
    isLoading: false, isInitialized: false,
    startRedak: '', endRedak: '',
    heightCalendar: 650,
    month: '',
}

export function Dashboard() {
    const {mettingsCopy} = useSelector<RootState, {
        mettingsCopy: MettingType[]
    }>((state) => state.mettingsCopy)

    const {mettings} = useSelector<RootState, {
        mettings: MettingType[]
    }>((state) => state.mettings)

    const { id } = useParams();
    const planning: Array<MettingType> = findMettingByProofreader(id);

    const calendarRef = useRef<ToastUIReactCalendar>(null);

    const [state, setState] = useState<initialStateType>(initialState);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    useEffect(() => {
        function init() {
            // require('../../../asset/js/custom');
            setState((prevState: initialStateType) => ({
                ...prevState,
                heightCalendar: window.innerHeight - 250,
                isInitialized: true,
                isLoading: false,
            }));
            let containerCalendar = document.querySelectorAll<HTMLDivElement>('.tui-full-calendar-vlayout-container > div[data-auto-height=true]');
            containerCalendar.forEach((container) => {
                container.style.height = state.heightCalendar + 'px';
            })
            if(calendarRef.current !== null) {
                onChangeCurrentMonth();
            }
        }
        if(!state.isInitialized) {
            init();
        }
    })

    const handleChangeDate = (value: string, property: string, type: string) => {
        setState((prevState: initialStateType) => ({ ...prevState, [property]: value }));
        let results: Array<MettingType> = FilterDateMetting(mettingsCopy, property, value, type);
        planning.map((metting) => {
            calendarRef.current?.getInstance()?.deleteSchedule(String(metting.id), '0');
        });
        calendarRef.current?.getInstance()?.createSchedules(prepareCalendar(results))
    }

    const onChangeCurrentMonth = () => {
        if(null !== calendarRef.current?.getInstance()) {
            // @ts-ignore
            const date = calendarRef.current.getInstance();
            let month = moment(date?.getDate().getTime()).format('MMMM');
            let year = moment(date?.getDate().getTime()).format('YYYY');
            setState((prevState: initialStateType) => ({
                ...prevState, month: `${month.toUpperCase()} ${year}`
            }));
        }
    }

    const onChangeMonthPrev = () => {
        calendarRef.current?.getInstance()?.prev();
        onChangeCurrentMonth();
    }

    const onChangeMonthNext = () => {
        calendarRef?.current?.getInstance()?.next();
        onChangeCurrentMonth();
    }

    const onChangeHeightCalendar = async  (height: number) => {
        if(height >= (window.innerHeight - 310)) {
            setState((prevState: initialStateType) => ({
                ...prevState, heightCalendar: height
            }));
            let containerCalendar = document.querySelectorAll<HTMLDivElement>('.tui-full-calendar-vlayout-container > div[data-auto-height=true]');
            containerCalendar.forEach((container) => {
                container.style.height = height + 'px';
            })

            planning.map((metting) => {
                calendarRef.current?.getInstance()?.deleteSchedule(String(metting.id), '0');
            });

            calendarRef.current?.getInstance()?.createSchedules(prepareCalendar(planning))
        }
    }

    const onHandleReset = () => {
        setState((prevState: initialStateType) => ({
            ...prevState,
            startRedak: '',
            endRedak: ''
        }));
        planning.map((metting: MettingType) => {
            calendarRef.current?.getInstance()?.deleteSchedule(String(metting.id), '0');
        });
        calendarRef.current?.getInstance()?.createSchedules(prepareCalendar(mettings))
    }

    const handleShow = (schedule: ISchedule) => {
        let metting: MettingType | undefined = mettingsCopy
            .find((metting: MettingType) => metting.id === Number(schedule.id));
        if(metting !== undefined) {
            dispatch(
                push(
                    <MettingShow initialMetting={metting} />, {
                        title: metting.buisness,
                        className: '',
                        type: 'update'
                    },
                ),
            );
        }
    }

    return (
        <div>
            <HeaderPlanningCalendar
                onChangeHeightCalendar={onChangeHeightCalendar}
                onChangeMonthPrev={onChangeMonthPrev}
                onChangeMonthNext={onChangeMonthNext}
                handleChangeDate={handleChangeDate}
                heightCalendar={state.heightCalendar}
                onHandleReset={onHandleReset}
                month={state.month}
                start={state.startRedak}
                end={state.endRedak}
            />
            {
                !state.isLoading && (
                    <PlanningCalendar
                        calendarRef={calendarRef}
                        handleShow={handleShow}
                        height={state.heightCalendar}
                        mettings={planning}
                    />
                )
            }
        </div>
    )
}