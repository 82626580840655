import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";

export enum ProofreaderActionType {
    SET_PROOFREADERS = 'SET_PROOFREADERS',
    PUSH_PROOFREADER = 'PUSH_PROOFREADER',
    SET_PROOFREADERS_COPY = 'SET_PROOFREADERS_COPY',
    RESET_PROOFREADERS_COPY = 'RESET_PROOFREADERS_COPY'
}

export interface IProofreaderBaseActions {
    type: ProofreaderActionType,
    payload?: any
}

const setProofreaders = (proofreaders: Array<ProofreaderType>) => (
    { type: ProofreaderActionType.SET_PROOFREADERS, payload: proofreaders }
)

const pushProofreader = (proofreader: ProofreaderType) => (
    { type: ProofreaderActionType.PUSH_PROOFREADER, payload: proofreader }
)

const setProofreadersCopy = (proofreaders: Array<ProofreaderType>) => (
    { type: ProofreaderActionType.SET_PROOFREADERS_COPY, payload: proofreaders }
)

const resetProofreadersCopy = () => (
    { type: ProofreaderActionType.RESET_PROOFREADERS_COPY }
)

export default {
    setProofreaders,
    pushProofreader,
    setProofreadersCopy,
    resetProofreadersCopy
}