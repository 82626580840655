export const message = {
    proofreader: {
        alert: {
            closing: 'En cliquant sur le bouton « Clôturer les index » vous ne pourrez plus modifier les informations de cette réunion.',
        },
        button: {
            details: 'Détails des index',
            closing: 'Clôturer mes index',
            updateEntryHours: 'Modifier mes index',
            editEntryHours: 'Entrer mes index',
        }
    },
    manager: {
        alert: {
            notDateMetting: 'La réunion n\'a pas de date de début et de fin. Veuillez compléter la réunion.'
        },
        warning: {
            statusDefault: 'La réunion n\'est pas disponible pour le rédacteur. Veuillez modifier son état pour la rendre disponible à la saisie des times codes.'
        }
    },
    toaster: {
        error: {
            time: 'Une erreur a été rencontrée. Veuillez vérifier les champs renseignés.',
            metting: 'Une erreur a été rencontrée. Veuillez vérifier les champs renseignés.',
            accessDenied: 'Vous n\'êtes pas autorisé à accéder à l\'application.',
            mailer: 'Une erreur a été rencontrée. Veuillez réessayer.'
        },
        success: {
            mailer: {
                create: 'L\'e-mail a été envoyé avec succès !'
            },
            metting: {
                update: 'La réunion a été enregistrée avec succès !'
            },
            time: {
                update: 'L\'index a été enregistré avec succès !'
            },
            closing: {
                update: 'La clôture a été enregistrée avec succès !'
            },
            resetPassword: 'Un nouveau mot de passe a été envoyé par e-mail.'
        }
    }
}

export default message;