import React from "react";

export const ProgressBar = (props: {number: number}) => {
    const {number} = props;
    return (
        <div
            className="app-progress-bar"
        >
            <div
                className="app-content-progress-bar"
                style={{width: `${number}%`}}
            >
            </div>
            <span>{`${Number.parseFloat(String(number)).toFixed(2)} %`}</span>
        </div>
    )
}