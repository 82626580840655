import React from "react";
import {Route, Routes} from "react-router-dom";
import {Container} from "@Redak/Common/Container";
import Planning from "@Redak/Screen/Proofreader/Planning";
import Profile from "@Redak/Screen/Proofreader/Profile";

export function Proofreader() {
    return (
        <>
            <div className="page-wrapper ml-0">
                <div className="container">
                    <Container>
                        <Routes>
                            <Route path={'/planning'} element={<Planning/>}/>
                            <Route path={'/profile'} element={<Profile/>}/>
                        </Routes>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Proofreader;