import React, {useEffect, useState} from "react";
import {getMettingForSubscriberSocket} from "@Redak/Api/Metting/metting.api";
import {useAppDispatch} from "@Redak/redux/hooks";
import MettingActions from "@Redak/redux/reducers/Metting/actions";

export function SocketSubscriber() {
    const [isInitialized, setInitialized] = useState(false);
    const dispatch = useAppDispatch();
    const { updateMetting } = MettingActions;

    useEffect(() => {
        if(!isInitialized) _initialisation();
    }, [isInitialized]);

    const _initialisation = () => {
        setInitialized(true);
        let socket: WebSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}`);

        socket.onmessage = async function (response: MessageEvent) {
            const data = JSON.parse(response.data).message
            if(data.id) {
                try {
                    const response = await getMettingForSubscriberSocket(data.id);
                    dispatch(updateMetting(response.data));
                }catch (error) {
                    console.log(error)
                }
            }
        }

        socket.onerror = function (error: Event) {
            console.log(error)
        }

    }

    return (
        <></>
    );
}

export default SocketSubscriber;