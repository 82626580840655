import moment, {Moment} from "moment";
import React from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {TimeEntry} from "@Redak/types/WorkTime/WorkTimeType";

export function minutesToHHMM(numberOfMinute: number): string {
    const duration = moment.duration(numberOfMinute, 'minutes');
    const hh = (duration.years()*(365*24)) + (duration.months()*(30*24)) + (duration.days()*24) + (duration.hours());
    const mm = duration.minutes();

    return hh + 'h ' + mm + 'min';
}

export function minutesToHHMMForInputTime(value: number | string): string {
    if (typeof value === 'number') {
        let hours = Math.floor(value / 60).toString().padStart(2, '0')
        let minutes = String(value % 60).toString().padStart(2, '0');

        return hours + ':' + minutes;
    }

    return value;
}

export function transformInputTimeOfNumber(value: string): number {
    let hours: number = Number(value.slice(0,2)) * 60;
    hours += Number(value.slice(3,5));

    return hours;
}

export function calculatePourcentage(metting: MettingType, time: TimeEntry | undefined): number {
    if (time === undefined || time.timeCode === null) {
        return 0.00;
    }

    let pourcentage = Math.round(transformInputTimeOfNumber(time.timeCode) * 100 / metting.estimateDurationWorkTime)
    if(String(pourcentage) === 'NaN' || String(pourcentage) === 'Infinity') {
        return 0.00;
    }

    return pourcentage;
}

export function calculateTimeProgressBar(metting: MettingType): number {
    let duration: number = 0;

    let times = metting.timeProofreader?.times;
    if (times?.length === undefined || times.length <= 0) {
        return duration;
    }

    let lastTime = times[times.length - 1].timeCode;
    if (lastTime === null) {
        return duration;
    }

    duration = transformInputTimeOfNumber(lastTime);

    let pourcentage = Math.round(duration * 100 / metting.estimateDurationWorkTime)
    if(String(pourcentage) === 'NaN' || String(pourcentage) === 'Infinity') {
        return 0;
    }

    return pourcentage;
}

export function transformInputTimeOfObjectMoment(time: string): Moment {
    return moment({
        hours: Number(time.slice(0,2)),
        minutes: Number(time.slice(3,5))
    });
}

// export function calculateTotalDuration(times: WorkTimeEntryType[]): string
// {
//     let duration: number = 0;
//     times.map((time: WorkTimeEntryType) => {
//         duration +=Number(time.duration ) + 1;
//     });
//     return String(duration);
// }

export const TimeDurationTotale = (props: {duration: number}): React.ReactElement => {
    const { duration } = props;

    if(duration === null) {
        return <>--</>
    }

    return (
        <>
            {minutesToHHMM(Number(duration))}
        </>
    )
}

export const TimeDuration = (props: {timeStart: string|null, timeEnd: string|null}): React.ReactElement => {
    const {timeStart, timeEnd} = props;

    if(timeStart === null || timeEnd === null) {
        return <>--</>
    }

    let start: Moment = transformInputTimeOfObjectMoment(timeStart);
    let end: Moment = transformInputTimeOfObjectMoment(timeEnd);
    let minutes: number = end.diff(start, 'minutes');

    if(minutes <= 0) {
        return <>--</>
    }

    return (
        <>
            {minutesToHHMM(end.diff(start, 'minutes'))}
        </>
    )
}