export enum ToastVariants {
    DANGER = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success'
}

export interface ToastType {
    readonly uuid: string,
    type: ToastVariants,
    message: string,
    delay: number
}