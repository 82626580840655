const BASE_SECURITY = '/security';
const BASE_MANAGEMENT = '/management';
const BASE_PROOFREADER = '/proofreader';

const ROUTE = {
    SECURITY: {
        AUTHENTIFICATION: BASE_SECURITY + '/signing',
        LOST_PASSWORD: BASE_SECURITY + '/reset-password',
    },
    MANAGEMENT: {
        CALENDAR: BASE_MANAGEMENT + '/calendar',
        TABLE: BASE_MANAGEMENT + '/table'
    },
    PROOFREADER: {
        METTINGS: BASE_PROOFREADER + '/planning'
    }
}

export default ROUTE;