import axios from "axios";
import API from "@Redak/Api/api.url";
import TokenStorage from "@Redak/Services/TokenStorage";

const postMailer = (form: any) => axios.post(
    API.BASE + API.MAILER.POST, form,
    TokenStorage.getAuthentification()
)

export {
    postMailer
}