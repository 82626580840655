import React, {ReactElement, ReactNode, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@Redak/redux/store";
import modalAction from "@Redak/redux/reducers/Modal/actions"

export function ContainerModal(props: {children: ReactNode}) {
    const {children} = props;
    const modalRef = useRef<HTMLDivElement>(null);
    const modals = useSelector<RootState, ReactElement[]>(
        (state) => state.modals.modals
    );
    const dispatch = useDispatch();

    const { setModals } = modalAction;

    const handleCloseModals = () => {
        const openModals = document.querySelectorAll<HTMLDivElement>('.modal-child-content');
        openModals.forEach((modal) => {
            modal.classList.remove('is-open-modal');
            modal.classList.add('is-close-modal');
        });

        setTimeout(() => {
            dispatch(setModals([]));
        }, 1000);
    }

    document.body.style.overflow = 'hidden';
    if(!modals.length) {
        document.body.style.overflow = 'visible';
        return <></>;
    }


    return (
        <>
            <div
                ref={modalRef}
                className="container-modal"
            >
                <div className={'container-modal-left-close'}
                     onClick={handleCloseModals}
                ></div>
                {children}
            </div>
        </>
    )
}

export default ContainerModal;