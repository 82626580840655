import React, {ReactElement} from "react";
import { v4 as uuid } from 'uuid';
import {store} from "@Redak/redux/store";
import Modal from "@Redak/Common/Modal/Modal";

export enum ModalActions {
    PUSH = 'PUSH_MODAL',
    SET_MODALS = 'SET_MODALS'
}

export interface IModalBaseActions {
    type: ModalActions,
    payload?: any
}

const setModals = (modals: ReactElement[]) => ({
    type: ModalActions.SET_MODALS,
    payload: modals
});

const push = (
    node: ReactElement,
    options: {
        title: string,
        type?: string,
        className: string,
    }
) => {
    const key = uuid();
    const allModals = store.getState().modals.modals;
    if(allModals.filter((m: ReactElement) => m && m.props.title === options.title).length > 0) {
        return {
            type: ModalActions.PUSH,
            payload: null
        };
    }
    const close = () => {
        const modals = allModals.filter((modal: ReactElement) => modal.key !== key);
        store.dispatch(setModals(modals));
    };
    const { title, className, type } = options;
    const modal = (
        <Modal
            className={className}
            identification={key}
            title={title}
            close={close}
            type={type}
            key={key}
        >
            {node}
        </Modal>
    )
    return {
        type: ModalActions.PUSH,
        payload: modal
    }
}

export default {
    setModals,
    push
}