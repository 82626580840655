import React, {useState} from "react";
import {useSelector} from "react-redux";
import {allStoreActions, RootState, store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {ProofreaderType} from "@Redak/types/Proofreader/ProofreaderType";
import {useAppDispatch} from "@Redak/redux/hooks";
import {StateType} from "@Redak/types/Config/ConfigType";
import {FilterDateMetting} from "@Redak/utils/Filters/FilterDateMetting";

interface initialStateType {
    startProofreader: string, endProofreader: string,
    startRedak: string, endRedak: string,
    search: string, state: string,
    identifier: string
}

const initialState: initialStateType = {
    startProofreader: '', endProofreader: '',
    startRedak: '', endRedak: '',
    search: '', state: '',
    identifier: ''
}

export function FilterTable() {
    const {proofreaders} = useSelector<RootState, {
        proofreaders: ProofreaderType[],
    }>((state) => state.proofreadersCopy);

    const {mettings} = useSelector<RootState, {
        mettings: MettingType[],
    }>((state) => state.mettingsCopy);

    let { states } = useSelector<RootState, {
        states: Array<StateType>
    }>((state) => state.config);

    const [state, setState] = useState<initialStateType>(initialState);

    const dispatch = useAppDispatch();

    const handleChangeSearch = (query: string) => {
        setState((prevState: initialStateType) => ({ ...prevState, search: query }));
        if(query.length) {
            let results: Array<ProofreaderType> = [];
            results = proofreaders.filter((proofreader: ProofreaderType) => proofreader.lastname.toUpperCase()
                .indexOf(query.toUpperCase()) > -1);
            dispatch(allStoreActions.proofreadersCopy.setProofreadersCopy(results));
        }
        else{
            dispatch(allStoreActions.proofreadersCopy.setProofreadersCopy(
                store.getState().proofreaders.proofreaders
            ));
        }
    }

    const handleChangeState = (value: string) => {
        setState((prevState: initialStateType) => ({ ...prevState, state: value }));
        let results: Array<MettingType> = [];
        mettings.map((metting: MettingType) => {
            if(String(metting.state?.id) === value) {
                results.push(metting);
            }
        });
        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(results));
    }

    const handleChangeDate = (date: string, property: string, type: string) => {
        setState((prevState: initialStateType) => ({ ...prevState, [property]: date }));
        let results: Array<MettingType> = FilterDateMetting(mettings, property, date, type);
        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(results));
    }

    const handleChangeId = (id: string) => {
        let results: Array<MettingType> = [];
        handleResetFilters();
        setState((prevState: initialStateType) => ({
            ...prevState, identifier: id
        }));

        mettings.map((metting: MettingType) => {
            if(String(metting.id) === id) {
                results.push(metting);
            }
        });

        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(results));
    }

    const handleResetFilters = () => {
        setState((prevState: initialStateType) => ({  ...prevState, ...initialState }));
        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(mettings));
        dispatch(allStoreActions.proofreadersCopy.resetProofreadersCopy());
    }

    return (
        <div className="container-filter-table">
            <div>
                <div className="d-flex">
                    <input
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        placeholder="Rechercher un rédacteur"
                        className="form-control mr-3"
                        style={{width: 250}}
                        value={state.search}
                        type="text"
                    />
                    <input
                        onChange={(e) => handleChangeDate(e.target.value, 'startRedak', 'AFTER')}
                        placeholder="Date de début rédacteur"
                        className="form-control mr-3"
                        value={state.startRedak}
                        style={{width: 250}}
                        type="date"
                    />
                    <input
                        onChange={(e) => handleChangeDate(e.target.value, 'endRedak', 'BEFORE')}
                        placeholder="Date de fin rédacteur"
                        className="form-control mr-3"
                        value={state.endRedak}
                        style={{width: 250}}
                        type="date"
                    />
                    <select
                        onChange={(e) => handleChangeState(e.target.value)}
                        placeholder="État de la réunion"
                        className="form-control mr-3"
                        style={{width: 250}}
                        value={state.state}
                    >
                        {states.map((option: StateType, index: number) => (
                            <option
                                value={option.id}
                                key={index}
                            >
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <hr style={{borderTop: '1px solid #fff' }} />
                <div className="d-flex">
                    <input
                        onChange={(e) => handleChangeId(e.target.value)}
                        placeholder="Rechercher une réunion par ID"
                        className="form-control mr-3"
                        value={state.identifier}
                        style={{width: 250}}
                        type="number"
                    />
                    <input
                        onChange={(e) => handleChangeDate(e.target.value, 'startProofreader', 'AFTER')}
                        placeholder="Date de début client"
                        value={state.startProofreader}
                        className="form-control mr-3"
                        style={{width: 250}}
                        type="date"
                    />
                    <input
                        onChange={(e) => handleChangeDate(e.target.value, 'endProofreader', 'BEFORE')}
                        placeholder="Date de fin client"
                        className="form-control mr-3"
                        value={state.endProofreader}
                        style={{width: 250}}
                        type="date"
                    />
                    <button className="btn btn-light text-center"
                            onClick={handleResetFilters}
                    >
                        Supprimer les filtres
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FilterTable;