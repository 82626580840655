import React, {useState} from 'react';
import FormField from "@Redak/Common/Forms/FormField";
import {IAuthentificationType} from "@Redak/types/Security/AuthentificationType";
import {Field, Formik} from "formik";
import SyncLoader from "react-spinners/ClipLoader";
import {authentification} from "@Redak/Api/User/user.api";
import Shape from "@Redak/types/Common/Shape";
import {NavLink, useNavigate} from "react-router-dom";
import * as yup from 'yup';
import ROUTE from "@Redak/Services/navigation.all";
import TokenStorage from "@Redak/Services/TokenStorage";
import userActions from "@Redak/redux/reducers/User/actions";
import {useAppDispatch} from "@Redak/redux/hooks";

const signingSchema = yup.object<Shape<IAuthentificationType>>({
    username: yup.string().required('L\'identifiant est requis.'),
    password: yup.string().required('Le mot de passe est requis.'),
});

export function Signing() {
    const [state, setState] = useState<{ isLoading: boolean, error: string | null }>({
        isLoading: false, error: null
    });
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    const submit = (form: any, action: any) => {
        setState((prevState) =>
            ({...prevState, isLoading: true})
        );

        authentification(form).then((response) => {
            TokenStorage.storeToken(response.data.token);
            setState(
                (prevState) =>
                    ({
                        ...prevState,
                        error: null,
                        isLoading: false
                    })
            );
            dispatch(userActions.setInitialized(false));
            return navigation('/');
        }).catch((error) => {
            action.resetForm();
            setState(
                (prevState) =>
                    ({
                        ...prevState,
                        error: 'Votre identifiant ou votre mot de passe n\'est pas correct.',
                        isLoading: false
                    })
            );
        });
    }

    return (
        <Formik initialValues={{username: '', password: ''}}
                validationSchema={signingSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={submit}
        >
            {
                ({handleSubmit, errors}) => (
                    <form className="form-horizontal form-material" onSubmit={handleSubmit}>

                        <h3 className="text-center m-b-40">Espace de connexion</h3>

                        <FormField error={errors.username}>
                            <Field placeholder={'Identifiant'}
                                   className="form-control"
                                   maxLength={255}
                                   name="username"
                                   type="text"
                            />
                        </FormField>

                        <FormField error={errors.password}>
                            <Field placeholder={'Mot de passe'}
                                   className="form-control"
                                   maxLength={255}
                                   name="password"
                                   type="password"
                            />
                        </FormField>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="d-flex no-block align-items-center">
                                    <div className="custom-control custom-checkbox">
                                    </div>
                                    <div className="ml-auto">
                                        <NavLink className="text-muted" to={ROUTE.SECURITY.LOST_PASSWORD}>
                                            <i className="fas fa-lock m-r-5"></i> Mot de passe oublié ?
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {state.error && (<div className="text-danger text-error-form mb-2">{state.error}</div>)}

                        <div className="form-group text-center">
                            <div className="col-xs-12 p-b-20">
                                <button className="btn btn-block btn-lg btn-info btn-rounded"
                                        disabled={state.isLoading}
                                        type="submit"
                                >
                                    {
                                        state.isLoading ? (
                                            <SyncLoader color={'#fff'} loading={true} size={23}/>
                                        ) : (
                                            "Me connecter"
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    )
}

export default Signing;