import {StateType} from "@Redak/types/Config/ConfigType";

export interface SelectOptionType {
    value: string | number,
    label: string,
}

interface SelectType {
    name: string,
    setFieldValue: Function,
    defaultValue?: string | number,
    options: Array<SelectOptionType|StateType>
}

export function Select(props: SelectType) {
    const {setFieldValue, options, defaultValue, name} = props
    return (
        <select
            onChange={(e) => setFieldValue(name, e.target.value)}
            className="custom-select"
            value={defaultValue}
            name={name}
        >
            {options.map((option: SelectOptionType|StateType, index: number) => (
                <option
                    value={option.value}
                    key={index}
                >
                    {option.label}
                </option>
            ))}
        </select>
    )
}