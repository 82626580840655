import React, {useEffect, useState} from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {CardMetting} from "@Redak/Common/Metting/Card/CardMetting";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {Alert} from "@Redak/Common/Alert";
import message from "@Redak/utils/Data";
import {useAppDispatch} from "@Redak/redux/hooks";
import {findMettingById} from "@Redak/utils/Metting/findMetting";
import {useSelector} from "react-redux";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import {putWorkTime} from "@Redak/Api/WorkTime/worktime.api";
import {allStoreActions} from "@Redak/redux/store";
import {ToastVariants} from "@Redak/Common/Toaster/type";
import {AlertVariants} from "@Redak/Common/Alert/type";
import {TimeWorkTableDetails} from "@Redak/Common/Metting/View/Proofreader/Table/TimeWorkTableDetails";
import MettingEntryHoursEdit from "@Redak/Common/Metting/View/Proofreader/MettingEntryHoursEdit";

export function MettingEntryClosing(props: { initialMetting: MettingType }) {
    let {initialMetting} = props;

    const modals = useSelector((state: any) => state.modals.modals);
    const [metting, setMetting] = useState<MettingType>(initialMetting);
    const [isInitialized, setInitialized] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const { sendToast } = allStoreActions.toaster;

    const dispatch = useAppDispatch();
    const { updateMettingWorkTime } = MettingActions;
    const { push } = ModalActions;

    const init = async () => {
        setMetting(await findMettingById(initialMetting));
    }

    useEffect(() => {
        init().then(() => setInitialized(false));
    }, [modals.length])

    const handleClosingMetting = async () => {
        setLoading(true);
        const response = await putWorkTime(Number(metting.timeProofreader?.id), {
            isFinished: true
        });
        dispatch(updateMettingWorkTime(metting, response.data));
        dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.closing.update));
        await init();
        setLoading(false);
    }

    const handleShowEdit = () => {
        dispatch(
            push(
                <MettingEntryHoursEdit metting={metting} isManagement={false} />, {
                    title: metting.buisness.toUpperCase() + ' ',
                    className: 'modal-child-proofreader',
                    type: 'update'
                }
            )
        );
    }

    if(isInitialized) {
        return <></>;
    }

    return (
        <>
            {!metting.timeProofreader?.isFinished && (
                <Alert
                    alert={{
                        message: message.proofreader.alert.closing,
                        type: AlertVariants.DANGER,
                    }}
                />
            )}

            <CardMetting metting={metting} isAction={false} />

            {metting.timeProofreader?.times?.length ? (
                <TimeWorkTableDetails metting={metting} />
            ) : <></>}

            {!metting.timeProofreader?.isFinished && (
                <>
                    <div className="form-group d-flex justify-content-start w-100 mt-4">
                        <button
                            className="btn btn-success"
                            onClick={handleShowEdit}
                            disabled={isLoading}
                        >
                            {message.proofreader.button.updateEntryHours}
                        </button>
                        {metting.timeProofreader?.times?.length ? (
                            <button
                                className="btn btn-primary ml-3"
                                onClick={handleClosingMetting}
                                disabled={isLoading}
                            >
                                {message.proofreader.button.closing}
                            </button>
                        ) : <></>}
                    </div>
                </>
            )}
        </>
    )
}

export default MettingEntryClosing;