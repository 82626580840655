import {ConfigType} from "@Redak/types/Config/ConfigType";

export enum ConfigActionsType {
    SET_CONFIG = 'SET_CONFIG'
}

export interface IConfigBaseActions {
    type: ConfigActionsType,
    payload?: any
}

const setConfig = (config: Array<ConfigType>) => (
    {type: ConfigActionsType.SET_CONFIG, payload: config}
)

export default {
    setConfig
}