import {UserType} from "@Redak/types/User/UserType";
import {IUserBaseActions, UserActionType} from "@Redak/redux/reducers/User/actions";

export interface UserStateType {
    user: UserType | null,
    isInitialized: boolean,
}

export const initialUserState: UserStateType = {
    user: null,
    isInitialized: false
}

export const userReducer = (
    state: any = initialUserState, action: IUserBaseActions
) => {
    switch (action.type) {
        case UserActionType.SET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case UserActionType.SET_INITIALIZED: {
            return {
                ...state,
                isInitialized: action.payload
            }
        }
        default: {
            return state;
        }
    }
}