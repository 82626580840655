import {ReactElement} from "react";
import {IModalBaseActions, ModalActions} from "@Redak/redux/reducers/Modal/actions";

export type ModalStateType = {
    modals: ReactElement[]
}

export const initialModalState: ModalStateType = {
    modals: []
}

export function modalReducer(
    state: ModalStateType = initialModalState,
    action: IModalBaseActions
) {
    switch (action.type) {
        case ModalActions.SET_MODALS: {
            if(action.payload) {
                return {
                    ...state,
                    modals: action.payload
                }
            }
            return state;
        }
        case ModalActions.PUSH: {
            return {
                ...state,
                modals: [
                        ...state.modals,
                    action.payload
                ]
            }
        }
        default:
            return state;
    }
}