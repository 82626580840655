import { v4 as uuidV4 } from 'uuid';
import {ToastType, ToastVariants} from "@Redak/Common/Toaster/type";

export class Toaster {
    readonly  uuid: string = uuidV4();

    public type: ToastVariants;

    public delay: number;

    public message: string;

    constructor(toast: ToastType) {
        this.type = toast.type;
        this.message = toast.message;
        this.delay = toast.delay || 3000;
    }
}